import React, { useMemo } from "react";
import Select from "react-select";
import cs from "classnames";
import { useContacts } from "../../../../../api/grpc/contacts/useContacts";
import { useCalendarContext } from "../../../../../lib/context/Calendar/CalendarContext";
import useGrpcQuery from "../../../../../lib/hooks/useGrpcQuery";
import { useDirectorySearch } from "../../../../../lib/hooks/useDirectorySearch";

import {
  ListPeopleFilter,
  ListPeopleRequest,
  People,
  Person,
} from "../../../../../api/grpc/contacts/contacts";
import { LoadingBox } from "../../../../shared/LoadingBox/LoadingBox";
import { SearchUserCustomOption } from "../../../../shared/Forms/SearchUserInput/SearchUserCustomOption";
import { DirectoryMenuList } from "../../../../shared/DirectoryMenuList/DirectoryMenuList";

type OptionType = {
  value: Person;
  label: string;
};

interface Props {
  onChange: (values: Person[]) => void;
  attendees: Person[];
  isAddPeople?: boolean;
}

export const FindPeopleSelector = ({
  onChange,
  attendees,
  isAddPeople,
}: Props) => {
  const { listPeople } = useContacts();
  const { personalCalendar } = useCalendarContext();
  const calendarID = isAddPeople ? personalCalendar[0]?.iD || "" : "";

  // Initial query for people data
  const { loading, error, data } = useGrpcQuery<ListPeopleRequest, People>({
    method: listPeople,
    variables: {
      personalCalendarID: calendarID,
      customerID: "",
      query: "",
      accountID: "",
      filter: ListPeopleFilter.ALL,
      limit: 5,
    },
  });

  // use the hook for the directory search logic
  const {
    searching,
    searchResults: hookSearchResults,
    handleDirectorySearch,
  } = useDirectorySearch(listPeople, calendarID);

  // Choose hook results if available; otherwise fallback to initial queried data
  const effectiveSearchResults = hookSearchResults || data;

  const options = useMemo(() => {
    return effectiveSearchResults?.people.map((item) => ({
      value: item,
      label: item.displayName,
    })) as OptionType[];
  }, [data, hookSearchResults, effectiveSearchResults]);

  const selectedValue = useMemo(() => {
    return attendees.map((attendee) => ({
      value: attendee,
      label: attendee.displayName,
    })) as OptionType[];
  }, [attendees]);

  return (
    <>
      <h6 className="pb-2">{isAddPeople ? "Add" : "Find"} people</h6>
      {loading ? (
        <LoadingBox minHeight={50} />
      ) : error ? (
        <div>Error fetching people, please try again.</div>
      ) : (
        <Select<OptionType, true>
          isClearable
          isMulti
          options={options}
          value={selectedValue}
          classNamePrefix={selectedValue.length >= 2 ? "MultipleVal" : ""}
          noOptionsMessage={() => "No result found"}
          components={{
            MenuList: (props) => (
              <DirectoryMenuList
                {...props}
                searching={searching}
                handleDirectorySearch={handleDirectorySearch}
              />
            ),
            Option: SearchUserCustomOption as any,
          }}
          onChange={(selected) => {
            if (!selected) {
              return;
            }
            const values = (
              Array.isArray(selected) ? selected : [selected]
            ).map((item) => item.value);
            onChange(values);
          }}
          className={cs("FindPeople FindAllPeople no-min-height NoOption", {
            NoOption__hide: searching,
          })}
        />
      )}
    </>
  );
};
