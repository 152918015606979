import React, { useMemo, useState } from "react";
import Select from "react-select";
import cs from "classnames";

import { useDirectorySearch } from "../../../../lib/hooks/useDirectorySearch";
import { useBookingsMapContext } from "../../BookingsMap/Context/BookingsMapContext";
import {
  People,
  Person,
  ListPeopleRequest,
} from "../../../../api/grpc/contacts/contacts";
import { UserModal } from "../../shared/UserModal/UserModal";
import { SearchUserCustomOption } from "../../../shared/Forms/SearchUserInput/SearchUserCustomOption";
import { DirectoryMenuList } from "../../../shared/DirectoryMenuList/DirectoryMenuList";

interface Props {
  error: any;
  data?: People;
  bookOnBehalf?: boolean;
  listPeople: (request: ListPeopleRequest) => Promise<any>;
  personalCalendarID: string;
}

export const SelectPeopleList = ({
  error: initialError,
  data: initialData,
  bookOnBehalf,
  listPeople,
  personalCalendarID,
}: Props) => {
  const [selectedUser, setSelectedUser] = useState<Person | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const { setBookOnBehalfUser } = useBookingsMapContext();

  const { searching, searchError, searchResults, handleDirectorySearch } =
    useDirectorySearch(listPeople, personalCalendarID);

  const effectiveSearchResults = searchResults || initialData;

  const options = useMemo(() => {
    return effectiveSearchResults?.people.map((item) => ({
      value: item,
      label: item.displayName,
    }));
  }, [effectiveSearchResults]);

  const handleSelectChange = (person: Person | null) => {
    if (!person) {
      setSelectedUser(null);
      return;
    }

    setSelectedUser(person);

    if (bookOnBehalf) {
      setBookOnBehalfUser(person);
    } else {
      setIsOpen(true);
    }
  };

  const selectedValue = useMemo(() => {
    return selectedUser
      ? { label: selectedUser.displayName, value: selectedUser }
      : null;
  }, [selectedUser]);

  return (
    <>
      {initialError || searchError ? (
        <div>Error fetching people, please try again.</div>
      ) : (
        <Select
          isClearable
          options={options}
          placeholder={bookOnBehalf ? "Search user..." : "Find people"}
          onChange={(e) => handleSelectChange(e?.value || null)}
          value={selectedValue}
          noOptionsMessage={() => "No result found"}
          components={{
            MenuList: (props) => (
              <DirectoryMenuList
                {...props}
                searching={searching}
                handleDirectorySearch={handleDirectorySearch}
              />
            ),
            Option: SearchUserCustomOption as any,
          }}
          className={cs(
            "FindPeople FindPeopleSearchDesk no-min-height NoOption",
            { NoOption__hide: searching }
          )}
        />
      )}

      {isOpen && (
        <UserModal
          person={selectedUser}
          isOpen={isOpen}
          handleModalClose={() => setIsOpen(false)}
        />
      )}
    </>
  );
};
