import React from "react";
import { OptionProps, components } from "react-select";
import { GroupTypeBase } from "react-select";

type OptionType = {
  label: string;
  value: string;
  __isNew__?: boolean;
};

export const SearchUserCustomOption: React.FC<
  OptionProps<OptionType, true, GroupTypeBase<OptionType>>
> = (props) => {
  const { data, children, innerRef, innerProps, isFocused } = props;

  return (
    <div
      ref={innerRef}
      {...innerProps}
      style={{
        height: "37px",
        display: "flex",
        alignItems: "center",
        padding: data.__isNew__ ? "0 5px" : "0 12px",
        cursor: "pointer",
        borderTop: data.__isNew__ ? "1px solid #ddd" : "none",
        marginLeft: data.__isNew__ ? "12px" : "0",
        marginRight: data.__isNew__ ? "12px" : "0",
        color: data.__isNew__ ? "black" : "#4d4d4d",
        backgroundColor: isFocused && !data.__isNew__ ? "#f0f0f0" : "white",
      }}
    >
      {children}
    </div>
  );
};
