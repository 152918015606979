import { useApolloClient } from "@apollo/client";

import {
  GetWorkplacesResponse,
  GET_ROOT_WORKPLACES,
  WorkplaceRequestVariables,
} from "../workplaces";

import { SITE_FIELDS_FRAGMENT } from "../sites/sites";
import { COMPANY_FIELDS_FRAGMENT } from "../companies/companies";
import { BUILDING_FIELDS_FRAGMENT } from "../buildings/buildings";

import {
  AddFloorResponse,
  AddFloorVariables,
  ADD_FLOOR_TO_TENANT,
  UpdateFloorResponse,
  FloorRequestVariables,
  UPDATE_FLOOR,
  ADD_FLOOR_TO_COMPANY,
  ADD_FLOOR_TO_SITE,
  ADD_FLOOR_TO_BUILDING,
  FLOOR_FIELDS_FRAGMENT,
  REMOVE_FLOOR_MAP,
  UPDATE_FLOOR_MAP_SETTINGS,
  MAP_FIELDS_FRAGMENT,
  UpdateMapResponse,
  ADD_POI_TO_FLOOR,
  AddPOIResponse,
  AddPOIVariables,
  DeletePOIVariables,
  DeletePOIResponse,
  DELETE_POI_FROM_FLOOR,
  FloorPOI,
  UPDATE_POI,
  UpdatePOIVariables,
  UpdatePOIResponse,
} from "./floors";
import { WorkplaceTypes } from "../../../components/Workplaces/AddWorkplace/AddWorkplaceButton/AddWorkplaceButton";

interface RemoveMapVariables {
  id: string;
  mapId: string;
}

export interface UpdateMapVariables {
  id: string;
  opacity: number;
  showGridline: boolean;
  showTooltips: boolean;
  showRoomMarkers: boolean;
  url: string;
}

export const useFloorsRequests = () => {
  const client = useApolloClient();

  const addFloorToTenant = async (variables: FloorRequestVariables) => {
    return await client.mutate<AddFloorResponse, AddFloorVariables>({
      mutation: ADD_FLOOR_TO_TENANT,
      variables,
      update: (cache, newData) => {
        const previousQuery = cache.readQuery<
          GetWorkplacesResponse,
          WorkplaceRequestVariables
        >({
          query: GET_ROOT_WORKPLACES,
          variables: {
            tenantId: variables.tenantId,
          },
        });

        const tenantResults = previousQuery?.queryTenant[0];
        const newFloor = newData.data?.addFloor.floor[0];

        cache.writeQuery({
          query: GET_ROOT_WORKPLACES,
          variables: {
            tenantId: variables.tenantId,
          },
          data: {
            queryTenant: [
              {
                ...tenantResults,
                floors: [...(tenantResults?.floors || []), newFloor],
              },
            ],
          },
        });
      },
    });
  };

  const addFloorToCompany = async (variables: FloorRequestVariables) => {
    return await client.mutate<AddFloorResponse, AddFloorVariables>({
      mutation: ADD_FLOOR_TO_COMPANY,
      variables,
      update: (cache, newData) => {
        const previousData = client.readFragment({
          id: `Company:${variables.id}`,
          fragmentName: "CompanyFields",
          fragment: COMPANY_FIELDS_FRAGMENT,
        });

        client.writeFragment({
          id: `Company:${variables.id}`,
          fragmentName: "CompanyFields",
          fragment: COMPANY_FIELDS_FRAGMENT,
          data: {
            ...previousData,
            floors: [...previousData.floors, newData.data?.addFloor.floor[0]],
          },
        });
      },
    });
  };

  const addFloorToSite = async (variables: FloorRequestVariables) => {
    return await client.mutate<AddFloorResponse, FloorRequestVariables>({
      mutation: ADD_FLOOR_TO_SITE,
      variables,
      update: (cache, newData) => {
        const previousData = client.readFragment({
          id: `Site:${variables.id}`,
          fragmentName: "SiteFields",
          fragment: SITE_FIELDS_FRAGMENT,
        });

        client.writeFragment({
          id: `Site:${variables.id}`,
          fragmentName: "SiteFields",
          fragment: SITE_FIELDS_FRAGMENT,
          data: {
            ...previousData,
            floors: [...previousData.floors, newData.data?.addFloor.floor[0]],
          },
        });
      },
    });
  };

  const addFloorToBuilding = async (variables: FloorRequestVariables) => {
    return await client.mutate<AddFloorResponse, FloorRequestVariables>({
      mutation: ADD_FLOOR_TO_BUILDING,
      variables,
      update: (cache, newData) => {
        const previousData = client.readFragment({
          id: `Building:${variables.id}`,
          fragmentName: "BuildingFields",
          fragment: BUILDING_FIELDS_FRAGMENT,
        });

        client.writeFragment({
          id: `Building:${variables.id}`,
          fragmentName: "BuildingFields",
          fragment: BUILDING_FIELDS_FRAGMENT,
          data: {
            ...previousData,
            floors: [...previousData.floors, newData.data?.addFloor.floor[0]],
          },
        });
      },
    });
  };

  const addFloor = async (
    variables: FloorRequestVariables,
    parentType?: WorkplaceTypes
  ) => {
    if (parentType === "Company") {
      return await addFloorToCompany(variables);
    }

    if (parentType === "Site") {
      return await addFloorToSite(variables);
    }

    if (parentType === "Building") {
      return await addFloorToBuilding(variables);
    }

    return await addFloorToTenant(variables);
  };

  const updateFloor = async (variables: FloorRequestVariables) => {
    return await client.mutate<UpdateFloorResponse, FloorRequestVariables>({
      mutation: UPDATE_FLOOR,
      variables,
      update: (cache, newData) => {
        client.writeFragment({
          id: `Floor:${variables.id}`,
          fragmentName: "FloorFields",
          fragment: FLOOR_FIELDS_FRAGMENT,
          data: {
            ...newData.data?.updateFloor.floor[0],
          },
        });
      },
    });
  };

  const removeMapFromFloor = async (variables: RemoveMapVariables) => {
    return await client.mutate<UpdateFloorResponse, RemoveMapVariables>({
      mutation: REMOVE_FLOOR_MAP,
      variables,
      update: (cache, newData) => {
        const previousData = client.readFragment({
          id: `Floor:${variables.id}`,
          fragmentName: "FloorFields",
          fragment: FLOOR_FIELDS_FRAGMENT,
        });

        client.writeFragment({
          id: `Floor:${variables.id}`,
          fragmentName: "FloorFields",
          fragment: FLOOR_FIELDS_FRAGMENT,
          data: {
            ...previousData,
            marker: null,
          },
        });
      },
    });
  };

  const updateMapFloor = async (variables: UpdateMapVariables) => {
    return await client.mutate<UpdateMapResponse, UpdateMapVariables>({
      mutation: UPDATE_FLOOR_MAP_SETTINGS,
      variables,
      update: (cache, newData) => {
        const previousData = client.readFragment({
          id: `Map:${variables.id}`,
          fragmentName: "MapFields",
          fragment: MAP_FIELDS_FRAGMENT,
        });

        client.writeFragment({
          id: `Map:${variables.id}`,
          fragmentName: "MapFields",
          fragment: MAP_FIELDS_FRAGMENT,
          data: {
            ...previousData,
            map: {
              id: newData?.data?.updateMap.map[0].id,
              opacity: newData?.data?.updateMap.map[0]?.opacity,
              url: newData?.data?.updateMap.map[0]?.url,
              showGridline: newData?.data?.updateMap.map[0]?.showGridline,
              showTooltips: newData?.data?.updateMap.map[0]?.showTooltips,
              showRoomMarkers: newData?.data?.updateMap.map[0]?.showRoomMarkers,
            },
          },
        });
      },
    });
  };

  const addPOIToFloor = async (variables: AddPOIVariables) => {
    return await client.mutate<AddPOIResponse, AddPOIVariables>({
      mutation: ADD_POI_TO_FLOOR,
      variables: {
        floorId: variables.floorId,
        tenantId: variables.tenantId,
        type: variables.type,
        marker: {
          latitude: variables.marker.latitude,
          longitude: variables.marker.longitude,
        },
      },
      update: (cache, newData) => {
        const previousData = client.readFragment({
          id: `Floor:${variables.floorId}`,
          fragmentName: "FloorFields",
          fragment: FLOOR_FIELDS_FRAGMENT,
        });

        client.writeFragment({
          id: `Floor:${variables.floorId}`,
          fragmentName: "FloorFields",
          fragment: FLOOR_FIELDS_FRAGMENT,
          data: {
            ...previousData,
            floorPOI: [
              ...(previousData?.floorPOI || []),
              newData.data?.addPOI.pOI[0],
            ],
          },
        });
      },
    });
  };

  const deletePOI = async (variables: DeletePOIVariables) => {
    return await client.mutate<DeletePOIResponse, DeletePOIVariables>({
      mutation: DELETE_POI_FROM_FLOOR,
      variables: variables,
      update: (cache, newData) => {
        const previousData = client.readFragment({
          id: `Floor:${variables.floorId}`,
          fragmentName: "FloorFields",
          fragment: FLOOR_FIELDS_FRAGMENT,
        });

        client.writeFragment({
          id: `Floor:${variables.floorId}`,
          fragmentName: "FloorFields",
          fragment: FLOOR_FIELDS_FRAGMENT,
          data: {
            ...previousData,
            floorPOI: previousData.floorPOI.filter(
              (poi: FloorPOI) => !variables.id.includes(poi.id)
            ),
          },
        });
      },
    });
  };

  const updatePOI = async (variables: UpdatePOIVariables) => {
    return await client.mutate<UpdatePOIResponse, UpdatePOIVariables>({
      mutation: UPDATE_POI,
      variables: variables,
      update: (cache, newData) => {
        const previousData = client.readFragment({
          id: `Floor:${variables.floorId}`,
          fragmentName: "FloorFields",
          fragment: FLOOR_FIELDS_FRAGMENT,
        });

        client.writeFragment({
          id: `Floor:${variables.floorId}`,
          fragmentName: "FloorFields",
          fragment: FLOOR_FIELDS_FRAGMENT,
          data: {
            ...previousData,
            floorPOI: previousData.floorPOI.map((poi: FloorPOI) =>
              poi.id === variables.id
                ? { ...poi, marker: variables.marker }
                : poi
            ),
          },
        });
      },
    });
  };

  return {
    addFloor,
    updateFloor,
    removeMapFromFloor,
    updateMapFloor,
    addPOIToFloor,
    deletePOI,
    updatePOI,
  };
};
