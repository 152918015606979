import React, { useEffect } from "react";
import { useWorkplaceRequests } from "../../../../../api/graphql/useWorkplaceRequests";
import { useAuthContext } from "../../../../../lib/context/Auth/AuthContext";
import { useToggleModal } from "../../../../../lib/hooks/useToggleModal";
import { useTemplateContext } from "../../../../../lib/context/Templates/Templates/TemplatesContext";

import { capitalizeFirstLetter } from "../../../../../lib/helpers/capitalizeFirstLetter";
import { WorkplaceFields } from "../../../../../api/graphql/workplaces";
import { WorkplacesTreeLoader } from "../../../../../views/Manage/Workplaces/WorkplacesTree/WorkplacesTreeLoader/WorkplacesTreeLoader";
import { ApolloErrorGuard } from "../../../../shared/ApolloErrorGuard/ApolloErrorGuard";
import { HandleLoadingState } from "../../../../shared/HandleLoadingState/HandleLoadingState";
import { PageSidebar } from "../../../../shared/PageSidebar/PageSidebar";
import { PageSidebarHeader } from "../../../../shared/PageSidebar/PageSidebarHeader/PageSidebarHeader";
import { ItemsWorkspaceSelector } from "../ItemsWorkspaceSelector/ItemsWorkspaceSelector";
import { Button } from "../../../../shared/Button/Button";
import { WayFinderFormWorkspaces } from "../../../../shared/Forms/WayFinderForm/shared/WayFinderFormWorkspaces/WayFinderFormWorkspaces";
import { Icon } from "../../../../shared/Icon/Icon";
import { tooltips } from "../../../../../lib/utils/tooltips";

export const SelectItemsStep = () => {
  const { getRootWorkplaces } = useWorkplaceRequests();
  const { user } = useAuthContext();
  const { isOpen, toggleModal } = useToggleModal();
  const { activeProfile, pickedWorkspaces, setPickedWorkspaces } =
    useTemplateContext();

  const [request, { loading, error, data }] = getRootWorkplaces;

  const rootWorkplaces = Object.values(data?.queryTenant[0] || {}).filter(
    (item) => typeof item === "object"
  );

  const filterWorkplace = activeProfile?.type === "desk" ? "Room" : "Desk";

  useEffect(() => {
    request({ variables: { tenantId: user?.customerid || "" } });
  }, []);

  const handleWorkspaceDelete = (item: WorkplaceFields) => {
    const updatedWorkspaces = pickedWorkspaces.data.filter(
      (workspace) => workspace.id !== item.id
    );

    setPickedWorkspaces({
      data: updatedWorkspaces,
    });
  };

  return (
    <div className="SelectItemsStep">
      <Button
        icon="workplace-picker"
        title="Select workspaces"
        // color={emptyWorkspaces ? "outline-primary" : "primary"}
        color={"primary"}
        size="small"
        onClick={toggleModal}
        className="WayFinderFrom__workspace--opener mb-2"
      />

      <span className="d-block w-50-large pt-2 pb-2 label__main">
        Click to open the workspace picker and choose the workspaces to which
        the template should be transferred. Please note that you can only select
        workspaces of the same type as the template.
      </span>

      <span className="SelectItemsStep__selected d-block mb-3 mt-2">
        Selected template: {activeProfile?.label}
      </span>

      <WayFinderFormWorkspaces
        type="workspaces"
        data={pickedWorkspaces.data}
        hideCounter
        handleWorkspace={() => {}}
        onClick={handleWorkspaceDelete}
      />

      {isOpen && (
        <>
          <PageSidebar
            className={`PageSidebar--default PageSidebar--Custom PageSidebar--toggled PageSidebar--Wayfinder`}
            showOnInitialization={false}
            showOnDesktop={false}
            hideToggler
          >
            <PageSidebarHeader
              title={`Select ${capitalizeFirstLetter(
                activeProfile?.type || ""
              )}`}
              message={tooltips.templates.selectWorkspace}
              direction="left"
            />

            <div
              onClick={toggleModal}
              className="PageSidebar--Wayfinder--closer"
            >
              <Icon icon="close-icon" />
            </div>

            <div
              className="
      PageSidebar--Wayfinder--desc
      "
            >
              Select {capitalizeFirstLetter(activeProfile?.type || "")}s below
            </div>

            <HandleLoadingState
              loading={loading}
              loadingPlaceholder={<WorkplacesTreeLoader />}
            >
              <ApolloErrorGuard
                error={error}
                errorComponent={<p>Data couldn't be loaded.</p>}
              >
                <div className="default-scroll-y SelectItemsStep__items">
                  {rootWorkplaces.map(
                    (item: string | WorkplaceFields[] | undefined) => {
                      if (typeof item !== "object" || !item) {
                        return;
                      }

                      return item
                        .filter(
                          (i: WorkplaceFields) =>
                            i.__typename !== filterWorkplace &&
                            i.__typename !== "Wayfinder"
                        )
                        .map((workplace: WorkplaceFields) => {
                          return (
                            <ItemsWorkspaceSelector
                              key={`${workplace.id}-tree-root`}
                              item={workplace}
                              toggleParent={() => {}}
                              filterWorkplace={filterWorkplace}
                            />
                          );
                        });
                    }
                  )}
                </div>
              </ApolloErrorGuard>
            </HandleLoadingState>
            <div className="flex-a-center justify-content-between mt-3 WayFinderWorkspaceFooterPicker__buttons">
              <Button
                title="Clear"
                size="small"
                color="outline-secondary"
                onClick={() =>
                  setPickedWorkspaces({
                    data: [],
                  })
                }
              />
              <Button
                title="Confirm"
                size="small"
                color="primary"
                onClick={toggleModal}
              />
            </div>
          </PageSidebar>
          <div onClick={toggleModal} className="background__shadow"></div>
        </>
      )}
    </div>
  );
};
