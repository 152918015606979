export const getInitials = (name: string): string => {
  if (!name) {
    return "";
  }

  const words = name.trim().split(/\s+/);

  if (words.length === 1) {
    return words[0].charAt(0).toUpperCase();
  }

  const firstName = words[0];
  const lastName = words[words.length - 1];

  return (firstName.charAt(0) + lastName.charAt(0)).toUpperCase();
};
