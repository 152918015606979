import React, { PropsWithChildren, useState } from "react";
import { FormGroup } from "reactstrap";
import Select from "react-select";

import { useRoomTemplateContext } from "../../../../lib/context/Templates/RoomTemplate/RoomTemplateContext";
import { useRouter } from "../../../../lib/hooks/useRouter";
import { selectStyles } from "../../../../lib/utils/selectStyles";

import { HandleLoadingState } from "../../HandleLoadingState/HandleLoadingState";
import { LabelWithInformationBox } from "../../LabelWithInformationBox/LabelWithInformationBox";
import { LoadingBox } from "../../LoadingBox/LoadingBox";
import { HandleRequestState } from "../../HandleRequestState/HandleRequestState";
import { Button } from "../../Button/Button";
import { TEMPLATE_ROOT_PATH } from "../../../../lib/routes/routes";
import { tooltips } from "../../../../lib/utils/tooltips";

export const CreateRoomFromTemplate = ({
  fillFieldsFromProfile,
  resetFormikFields,
}: PropsWithChildren<{
  fillFieldsFromProfile?: (id: string) => void;
  resetFormikFields: () => void;
}>) => {
  const { history } = useRouter();
  const { roomTemplates, loadingRoomTemplate, errorRoomTemplates } =
    useRoomTemplateContext();
  const [selectedProfile, setSelectedProfile] = useState<{
    label: string;
    value: string;
  } | null>(null);

  const options = roomTemplates.map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  return (
    <FormGroup>
      <LabelWithInformationBox
        for="roomProfile"
        title="Use template settings"
        message={tooltips.templates.transferrTemplate}
        direction={"left"}
      />
      <HandleRequestState
        state={!!errorRoomTemplates}
        placeholder={
          <div>{errorRoomTemplates || "Error fetching room profiles"}</div>
        }
      >
        <HandleLoadingState
          loading={loadingRoomTemplate}
          loadingPlaceholder={<LoadingBox minHeight={60} />}
        >
          <div className="border-bottom pb-2">
            {roomTemplates.length > 0 ? (
              <Select
                name="roomProfile"
                isClearable
                isSearchable
                theme={selectStyles}
                options={options}
                value={selectedProfile ? selectedProfile : undefined}
                onChange={(e) => {
                  if (!e) {
                    setSelectedProfile(null);
                    return resetFormikFields();
                  }

                  if (fillFieldsFromProfile) {
                    setSelectedProfile({
                      label: e.label,
                      value: e.value,
                    });
                    return fillFieldsFromProfile(e.value);
                  }
                }}
                className={`${selectedProfile ? "Profile__active" : ""} mb-2`}
              />
            ) : (
              <Button
                title="Add new template"
                color="link"
                onClick={() => history.push(`${TEMPLATE_ROOT_PATH}/add-room`)}
                className="pl-0"
              />
            )}
          </div>
        </HandleLoadingState>
      </HandleRequestState>
    </FormGroup>
  );
};
