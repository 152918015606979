import React, { PropsWithChildren } from "react";
import { useApolloClient } from "@apollo/client";

import { DESK_FIELDS_FRAGMENT } from "../../../../api/graphql/desks/desks";

export const DeskErrorReservationCard = ({
  id,
}: PropsWithChildren<{
  id: string;
}>) => {
  const client = useApolloClient();

  const deskData = client.readFragment({
    id: `Desk:${id}`,
    fragmentName: "DeskFields",
    fragment: DESK_FIELDS_FRAGMENT,
  });

  //no need to display desk error
  if (!deskData?.licensed) {
    return null;
  }

  return (
    <div className="ReservationCard ErrorReservationCard default-box">
      <h6 className="overflow-text">{deskData?.name}</h6>

      <span>Failed to load workspace</span>
    </div>
  );
};
