import { useState } from "react";
import {
  ListPeopleFilter,
  ListPeopleRequest,
  People,
} from "../../api/grpc/contacts/contacts";

export type ListPeopleFunction = (
  request: ListPeopleRequest
) => Promise<{ response: People }>;

export function useDirectorySearch(
  listPeople: ListPeopleFunction,
  personalCalendarID: string
) {
  const [searching, setSearching] = useState(false);
  const [searchError, setSearchError] = useState<any>(null);
  const [searchResults, setSearchResults] = useState<People | undefined>();

  const handleDirectorySearch = async (searchQuery: string) => {
    try {
      setSearching(true);
      setSearchError(null);
      const { response } = await listPeople({
        personalCalendarID,
        customerID: "",
        query: searchQuery,
        accountID: "",
        filter: ListPeopleFilter.ALL,
        limit: 0,
      });
      setSearchResults(response);
    } catch (err) {
      setSearchError(err);
    } finally {
      setSearching(false);
    }
  };

  return {
    searching,
    searchError,
    searchResults,
    handleDirectorySearch,
    setSearchResults,
  };
}
