import React from "react";
import { FloorChildrenEntity } from "./FloorChildrenList";
import { FloorDeskExpandedList } from "./FloorDeskExpandedList";
import { FloorRoomExpandedList } from "./FloorRoomExpandedList";
import { FloorWayfinderExpandedList } from "./FloorWayfinderExpandedList";
import { FloorPoiExpandedList } from "./FloorPoiExpandedList";

export type FloorChildrenType =
  | "Room"
  | "Desk"
  | "Wayfinder"
  | "PointOfInterest";

interface ExpandedProps {
  data: FloorChildrenEntity[];
  onClose: () => void;
  parentId?: string;
  typeOfMap?: "bookings" | "floor";
}

interface Props extends ExpandedProps {
  type: FloorChildrenType;
}

const components: {
  [key in FloorChildrenType]: React.FunctionComponent<ExpandedProps>;
} = {
  Room: FloorRoomExpandedList,
  Desk: FloorDeskExpandedList,
  Wayfinder: FloorWayfinderExpandedList,
  PointOfInterest: FloorPoiExpandedList,
};

export const FloorChildrenExpandedList = ({
  type,
  data,
  onClose,
  typeOfMap,
}: Props) => {
  const Component = components[type];

  let componentProps: ExpandedProps = {
    data: data,
    onClose: onClose,
    typeOfMap: typeOfMap,
  };

  return <Component {...componentProps} />;
};
