// @generated by protobuf-ts 2.9.0 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "workplaces.proto" (syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { Workplaces } from "./workplaces";
import type { UpdateRDXDeviceRequest } from "./workplaces";
import type { UpdateRDXRoomDisplaySettingsResponse } from "./workplaces";
import type { UpdateRDXRoomDisplaySettingsRequest } from "./workplaces";
import type { GetRDXDeviceRoomResponse } from "./workplaces";
import type { GetRDXDeviceRoomRequest } from "./workplaces";
import type { DeleteRDXDeviceRequest } from "./workplaces";
import type { GetRDXDeviceRequest } from "./workplaces";
import type { BasicRDXDeviceResponse } from "./workplaces";
import type { UpdateRDXDeviceLEDStatusRequest } from "./workplaces";
import type { DeleteInterestedWorkplacesRequest } from "./workplaces";
import type { CreateInterestedWorkplacesResponse } from "./workplaces";
import type { CreateInterestedWorkplacesRequest } from "./workplaces";
import type { DeleteBookingDashboardItemRequest } from "./workplaces";
import type { GetBookingDashboardRequest } from "./workplaces";
import type { BookingDashboardResponse } from "./workplaces";
import type { CreateBookingDashboardRequest } from "./workplaces";
import type { ApplyDeskTemplateResponse } from "./workplaces";
import type { ApplyDeskTemplateRequest } from "./workplaces";
import type { DeleteDeskTemplateRequest } from "./workplaces";
import type { UpdateDeskTemplateRequest } from "./workplaces";
import type { DeskTemplatesResponse } from "./workplaces";
import type { ListDeskTemplatesRequest } from "./workplaces";
import type { DeskTemplate } from "./workplaces";
import type { CreateDeskTemplateRequest } from "./workplaces";
import type { ApplyRoomTemplateResponse } from "./workplaces";
import type { ApplyRoomTemplateRequest } from "./workplaces";
import type { DeleteRoomTemplateRequest } from "./workplaces";
import type { UpdateRoomTemplateRequest } from "./workplaces";
import type { RoomTemplatesResponse } from "./workplaces";
import type { ListRoomTemplatesRequest } from "./workplaces";
import type { RoomTemplate } from "./workplaces";
import type { CreateRoomTemplateRequest } from "./workplaces";
import type { GetDefaultLanguageResponse } from "./workplaces";
import type { GetDefaultLanguageRequest } from "./workplaces";
import type { UpdateRoomCalIntegrationStatusResponse } from "./workplaces";
import type { UpdateRoomCalIntegrationStatusRequest } from "./workplaces";
import type { GetMapEntityFloorIDResponse } from "./workplaces";
import type { GetMapEntityFloorIDRequest } from "./workplaces";
import type { SyncFloorMapMetadataResponse } from "./workplaces";
import type { SyncFloorMapMetadataRequest } from "./workplaces";
import type { OnLevelSearchResponse } from "./workplaces";
import type { OnLevelSearchRequest } from "./workplaces";
import type { SyncCustomLanguageFieldsRequest } from "./workplaces";
import type { UpdateCustomerGlobalCustomLanguagesResponse } from "./workplaces";
import type { UpdateCustomerGlobalCustomLanguagesRequest } from "./workplaces";
import type { BulkRetrieveCustomerGlobalCustomLanguagesResponse } from "./workplaces";
import type { BulkRetrieveCustomerGlobalCustomLanguagesRequest } from "./workplaces";
import type { RetrieveCustomerGlobalCustomLanguagesResponse } from "./workplaces";
import type { RetrieveCustomerGlobalCustomLanguagesRequest } from "./workplaces";
import type { DeleteCustomerGlobalCustomLanguagesRequest } from "./workplaces";
import type { ListCustomerLanguagesResponse } from "./workplaces";
import type { ListCustomerLanguagesRequest } from "./workplaces";
import type { CreateCustomerGlobalCustomLanguagesRequest } from "./workplaces";
import type { UpdateDeviceAppRequest } from "./workplaces";
import type { CheckDeviceVersionResponse } from "./workplaces";
import type { CheckDeviceVersionRequest } from "./workplaces";
import type { CustomLanguageResponse } from "./workplaces";
import type { GetCustomLanguageRequest } from "./workplaces";
import type { DeleteCustomLanguageRequest } from "./workplaces";
import type { UploadCustomLanguageResponse } from "./workplaces";
import type { UploadCustomLanguageRequest } from "./workplaces";
import type { GetConnectedFloorMapResponse } from "./workplaces";
import type { GetConnectedFloorMapRequest } from "./workplaces";
import type { SetDevicePingInfoRequest } from "./workplaces";
import type { SendErrorReportRequest } from "./workplaces";
import type { UpdateGlobalWorkingHrsResponse } from "./workplaces";
import type { UpdateGlobalWorkingHrsRequest } from "./workplaces";
import type { DesksBulkCreateResponse } from "./workplaces";
import type { DesksBulkCreateRequest } from "./workplaces";
import type { RDXFloorMapRequest } from "./workplaces";
import type { RFXFloorMapMetadataRequest } from "./workplaces";
import type { FloorMapMetadataRequest } from "./workplaces";
import type { FloorMapResponse } from "./workplaces";
import type { FloorMapRequest } from "./workplaces";
import type { GetDeskResponse } from "./workplaces";
import type { GetDeskRequest } from "./workplaces";
import type { GenerateQRCodeResponse } from "./workplaces";
import type { GenerateQRCodeRequest } from "./workplaces";
import type { DeleteMapRequest } from "./workplaces";
import type { MapResponse } from "./workplaces";
import type { MapRequest } from "./workplaces";
import type { CustomerActiveRoomsResponse } from "./workplaces";
import type { CustomerActiveRoomsRequest } from "./workplaces";
import type { ListRoomsResponse } from "./workplaces";
import type { ListRoomsRequest } from "./workplaces";
import type { GetRoomResponse } from "./workplaces";
import type { GetRoomRequest } from "./workplaces";
import type { RoomsInfoRequest } from "./workplaces";
import type { SameLevelDesksRequest } from "./workplaces";
import type { DesksInfoRequest } from "./workplaces";
import type { BatchLocationPathResponse } from "./workplaces";
import type { BatchSearchRequest } from "./workplaces";
import type { LocationPathResponse } from "./workplaces";
import type { RoomsInfoResponse } from "./workplaces";
import type { DesksListResponse } from "./workplaces";
import type { SearchAccountDesksRequest } from "./workplaces";
import type { SearchDesksResponse } from "./workplaces";
import type { SearchRequest } from "./workplaces";
import type { Tags } from "./workplaces";
import type { ListTagsRequest } from "./workplaces";
import type { ListAvailableDesksResponse } from "./workplaces";
import type { ListAvailableDesksRequest } from "./workplaces";
import type { AvailableRoomsResponse } from "./workplaces";
import type { ListAvailableRoomsRequest } from "./workplaces";
import type { DisconnectCalendarProviderResponse } from "./workplaces";
import type { NearbyRoomsResponse } from "./workplaces";
import type { NearbyRoomsRequest } from "./workplaces";
import type { CalendarRoomsResponse } from "./workplaces";
import type { CalendarRoomsRequest } from "./workplaces";
import type { Empty } from "./google/protobuf/empty";
import type { DeleteWorkplaceRequest } from "./workplaces";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { WorkplaceQueryResponse } from "./workplaces";
import type { WorkplaceQueryRequest } from "./workplaces";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service Workplaces
 */
export interface IWorkplacesClient {
    /**
     * Send a GraphQL query and get a raw json response
     * Current operation names supported:
     * * addDeviceMutation
     * * unpairDeviceMutation
     * * updateDisplaySettings
     *
     * @generated from protobuf rpc: WorkplaceQuery(WorkplaceQueryRequest) returns (WorkplaceQueryResponse);
     */
    workplaceQuery(input: WorkplaceQueryRequest, options?: RpcOptions): UnaryCall<WorkplaceQueryRequest, WorkplaceQueryResponse>;
    /**
     * @generated from protobuf rpc: DeleteWorkplace(DeleteWorkplaceRequest) returns (google.protobuf.Empty);
     */
    deleteWorkplace(input: DeleteWorkplaceRequest, options?: RpcOptions): UnaryCall<DeleteWorkplaceRequest, Empty>;
    /**
     * Get all rooms for calendar provider. Takes the customerID and calendarProviderId as query parameters.
     *
     * @generated from protobuf rpc: CalendarRooms(CalendarRoomsRequest) returns (CalendarRoomsResponse);
     */
    calendarRooms(input: CalendarRoomsRequest, options?: RpcOptions): UnaryCall<CalendarRoomsRequest, CalendarRoomsResponse>;
    /**
     * @generated from protobuf rpc: ListNearbyRooms(NearbyRoomsRequest) returns (NearbyRoomsResponse);
     */
    listNearbyRooms(input: NearbyRoomsRequest, options?: RpcOptions): UnaryCall<NearbyRoomsRequest, NearbyRoomsResponse>;
    /**
     * When deleting a calendar provider, this method is used to decouple rooms from calendars and
     * transfer paired devices to lobby mode
     *
     * @generated from protobuf rpc: DisconnectCalendarProvider(CalendarRoomsRequest) returns (DisconnectCalendarProviderResponse);
     */
    disconnectCalendarProvider(input: CalendarRoomsRequest, options?: RpcOptions): UnaryCall<CalendarRoomsRequest, DisconnectCalendarProviderResponse>;
    /**
     * List available rooms matching the filters and datetimes. Returns a list of available rooms.
     *
     * @generated from protobuf rpc: ListAvailableRooms(ListAvailableRoomsRequest) returns (AvailableRoomsResponse);
     */
    listAvailableRooms(input: ListAvailableRoomsRequest, options?: RpcOptions): UnaryCall<ListAvailableRoomsRequest, AvailableRoomsResponse>;
    /**
     * List available desks return all desks under location that available for booking process.
     *
     * @generated from protobuf rpc: ListAvailableDesks(ListAvailableDesksRequest) returns (ListAvailableDesksResponse);
     */
    listAvailableDesks(input: ListAvailableDesksRequest, options?: RpcOptions): UnaryCall<ListAvailableDesksRequest, ListAvailableDesksResponse>;
    /**
     * List all tags for all rooms under the set location. Returns a string list of tags.
     *
     * @generated from protobuf rpc: ListTags(ListTagsRequest) returns (Tags);
     */
    listTags(input: ListTagsRequest, options?: RpcOptions): UnaryCall<ListTagsRequest, Tags>;
    /**
     * SearchDesks return all desks down in hierarchy for location.
     *
     * @generated from protobuf rpc: SearchDesks(SearchRequest) returns (SearchDesksResponse);
     */
    searchDesks(input: SearchRequest, options?: RpcOptions): UnaryCall<SearchRequest, SearchDesksResponse>;
    /**
     * SearchAccountDesks return returns all desks assigned to the account
     *
     * @generated from protobuf rpc: SearchAccountDesks(SearchAccountDesksRequest) returns (DesksListResponse);
     */
    searchAccountDesks(input: SearchAccountDesksRequest, options?: RpcOptions): UnaryCall<SearchAccountDesksRequest, DesksListResponse>;
    /**
     * SearchRooms return all rooms down in hierarchy for location.
     *
     * @generated from protobuf rpc: SearchRooms(SearchRequest) returns (RoomsInfoResponse);
     */
    searchRooms(input: SearchRequest, options?: RpcOptions): UnaryCall<SearchRequest, RoomsInfoResponse>;
    /**
     * LocationPath return path from root to node.
     *
     * @generated from protobuf rpc: LocationPath(SearchRequest) returns (LocationPathResponse);
     */
    locationPath(input: SearchRequest, options?: RpcOptions): UnaryCall<SearchRequest, LocationPathResponse>;
    /**
     * BatchLocationPath return path from root to node for requested items.
     * Note: locations should be with the same type
     *
     * @generated from protobuf rpc: BatchLocationPath(BatchSearchRequest) returns (BatchLocationPathResponse);
     */
    batchLocationPath(input: BatchSearchRequest, options?: RpcOptions): UnaryCall<BatchSearchRequest, BatchLocationPathResponse>;
    /**
     * DesksInfo extract desks info from db
     *
     * @generated from protobuf rpc: DesksInfo(DesksInfoRequest) returns (DesksListResponse);
     */
    desksInfo(input: DesksInfoRequest, options?: RpcOptions): UnaryCall<DesksInfoRequest, DesksListResponse>;
    /**
     * SameLevelDesks return desks that at the same level as target desk
     *
     * @generated from protobuf rpc: SameLevelDesks(SameLevelDesksRequest) returns (DesksListResponse);
     */
    sameLevelDesks(input: SameLevelDesksRequest, options?: RpcOptions): UnaryCall<SameLevelDesksRequest, DesksListResponse>;
    /**
     * RoomsInfo extract rooms info from db
     *
     * @generated from protobuf rpc: RoomsInfo(RoomsInfoRequest) returns (RoomsInfoResponse);
     */
    roomsInfo(input: RoomsInfoRequest, options?: RpcOptions): UnaryCall<RoomsInfoRequest, RoomsInfoResponse>;
    /**
     * @generated from protobuf rpc: GetRoom(GetRoomRequest) returns (GetRoomResponse);
     */
    getRoom(input: GetRoomRequest, options?: RpcOptions): UnaryCall<GetRoomRequest, GetRoomResponse>;
    /**
     * @generated from protobuf rpc: ListRooms(ListRoomsRequest) returns (ListRoomsResponse);
     */
    listRooms(input: ListRoomsRequest, options?: RpcOptions): UnaryCall<ListRoomsRequest, ListRoomsResponse>;
    /**
     * @generated from protobuf rpc: CustomerActiveRooms(CustomerActiveRoomsRequest) returns (CustomerActiveRoomsResponse);
     */
    customerActiveRooms(input: CustomerActiveRoomsRequest, options?: RpcOptions): UnaryCall<CustomerActiveRoomsRequest, CustomerActiveRoomsResponse>;
    /**
     * @generated from protobuf rpc: UploadMap(MapRequest) returns (MapResponse);
     */
    uploadMap(input: MapRequest, options?: RpcOptions): UnaryCall<MapRequest, MapResponse>;
    /**
     * @generated from protobuf rpc: DeleteMap(DeleteMapRequest) returns (google.protobuf.Empty);
     */
    deleteMap(input: DeleteMapRequest, options?: RpcOptions): UnaryCall<DeleteMapRequest, Empty>;
    /**
     * GenerateQRCode return qr code base64 string for workplace entity
     * Support only room or desk entity type
     *
     * @generated from protobuf rpc: GenerateQRCode(GenerateQRCodeRequest) returns (GenerateQRCodeResponse);
     */
    generateQRCode(input: GenerateQRCodeRequest, options?: RpcOptions): UnaryCall<GenerateQRCodeRequest, GenerateQRCodeResponse>;
    /**
     * GetDesk return info for desk with free time slots
     *
     * @generated from protobuf rpc: GetDesk(GetDeskRequest) returns (GetDeskResponse);
     */
    getDesk(input: GetDeskRequest, options?: RpcOptions): UnaryCall<GetDeskRequest, GetDeskResponse>;
    /**
     * FloorMap return all rooms and desks in a floor with their free/busy status
     *
     * @generated from protobuf rpc: FloorMap(FloorMapRequest) returns (FloorMapResponse);
     */
    floorMap(input: FloorMapRequest, options?: RpcOptions): UnaryCall<FloorMapRequest, FloorMapResponse>;
    /**
     * FloorMap return initial flor map without rooms or desks statuses
     *
     * @generated from protobuf rpc: FloorMapMetadata(FloorMapMetadataRequest) returns (FloorMapResponse);
     */
    floorMapMetadata(input: FloorMapMetadataRequest, options?: RpcOptions): UnaryCall<FloorMapMetadataRequest, FloorMapResponse>;
    /**
     * RFXFloorMapMetadata return initial flor map for RFX without rooms or desks statuses
     *
     * @generated from protobuf rpc: RFXFloorMapMetadata(RFXFloorMapMetadataRequest) returns (FloorMapResponse);
     */
    rFXFloorMapMetadata(input: RFXFloorMapMetadataRequest, options?: RpcOptions): UnaryCall<RFXFloorMapMetadataRequest, FloorMapResponse>;
    /**
     * RDXFloorMapMetadata return floor map with connected room, nearby rooms and desks
     *
     * @generated from protobuf rpc: RDXFloorMap(RDXFloorMapRequest) returns (FloorMapResponse);
     */
    rDXFloorMap(input: RDXFloorMapRequest, options?: RpcOptions): UnaryCall<RDXFloorMapRequest, FloorMapResponse>;
    /**
     * DesksBulkCreate
     *
     * @generated from protobuf rpc: DesksBulkCreate(DesksBulkCreateRequest) returns (DesksBulkCreateResponse);
     */
    desksBulkCreate(input: DesksBulkCreateRequest, options?: RpcOptions): UnaryCall<DesksBulkCreateRequest, DesksBulkCreateResponse>;
    /**
     * UpdateGlobalWorkingHrs is a method for update global working hrs records
     *
     * @generated from protobuf rpc: UpdateGlobalWorkingHrs(UpdateGlobalWorkingHrsRequest) returns (UpdateGlobalWorkingHrsResponse);
     */
    updateGlobalWorkingHrs(input: UpdateGlobalWorkingHrsRequest, options?: RpcOptions): UnaryCall<UpdateGlobalWorkingHrsRequest, UpdateGlobalWorkingHrsResponse>;
    /**
     * SendErrorReport is a method for creating rdx error reporting
     *
     * @generated from protobuf rpc: SendErrorReport(SendErrorReportRequest) returns (google.protobuf.Empty);
     */
    sendErrorReport(input: SendErrorReportRequest, options?: RpcOptions): UnaryCall<SendErrorReportRequest, Empty>;
    /**
     * SetDevicePingInfo set date for device ping datetime
     *
     * @generated from protobuf rpc: SetDevicePingInfo(SetDevicePingInfoRequest) returns (google.protobuf.Empty);
     */
    setDevicePingInfo(input: SetDevicePingInfoRequest, options?: RpcOptions): UnaryCall<SetDevicePingInfoRequest, Empty>;
    /**
     * @generated from protobuf rpc: GetConnectedFloorMap(GetConnectedFloorMapRequest) returns (GetConnectedFloorMapResponse);
     */
    getConnectedFloorMap(input: GetConnectedFloorMapRequest, options?: RpcOptions): UnaryCall<GetConnectedFloorMapRequest, GetConnectedFloorMapResponse>;
    /**
     * @generated from protobuf rpc: UploadCustomLanguage(UploadCustomLanguageRequest) returns (UploadCustomLanguageResponse);
     */
    uploadCustomLanguage(input: UploadCustomLanguageRequest, options?: RpcOptions): UnaryCall<UploadCustomLanguageRequest, UploadCustomLanguageResponse>;
    /**
     * @generated from protobuf rpc: DeleteCustomLanguage(DeleteCustomLanguageRequest) returns (google.protobuf.Empty);
     */
    deleteCustomLanguage(input: DeleteCustomLanguageRequest, options?: RpcOptions): UnaryCall<DeleteCustomLanguageRequest, Empty>;
    /**
     * @generated from protobuf rpc: CustomLanguage(GetCustomLanguageRequest) returns (CustomLanguageResponse);
     */
    customLanguage(input: GetCustomLanguageRequest, options?: RpcOptions): UnaryCall<GetCustomLanguageRequest, CustomLanguageResponse>;
    /**
     * @generated from protobuf rpc: SyncDisplaySettingsCustomerID(google.protobuf.Empty) returns (google.protobuf.Empty);
     */
    syncDisplaySettingsCustomerID(input: Empty, options?: RpcOptions): UnaryCall<Empty, Empty>;
    /**
     * @generated from protobuf rpc: CheckDeviceVersion(CheckDeviceVersionRequest) returns (CheckDeviceVersionResponse);
     */
    checkDeviceVersion(input: CheckDeviceVersionRequest, options?: RpcOptions): UnaryCall<CheckDeviceVersionRequest, CheckDeviceVersionResponse>;
    /**
     * @generated from protobuf rpc: UpdateDeviceApp(UpdateDeviceAppRequest) returns (google.protobuf.Empty);
     */
    updateDeviceApp(input: UpdateDeviceAppRequest, options?: RpcOptions): UnaryCall<UpdateDeviceAppRequest, Empty>;
    /**
     * @generated from protobuf rpc: CreateCustomerGlobalCustomLanguages(CreateCustomerGlobalCustomLanguagesRequest) returns (google.protobuf.Empty);
     */
    createCustomerGlobalCustomLanguages(input: CreateCustomerGlobalCustomLanguagesRequest, options?: RpcOptions): UnaryCall<CreateCustomerGlobalCustomLanguagesRequest, Empty>;
    /**
     * @generated from protobuf rpc: ListCustomerLanguages(ListCustomerLanguagesRequest) returns (ListCustomerLanguagesResponse);
     */
    listCustomerLanguages(input: ListCustomerLanguagesRequest, options?: RpcOptions): UnaryCall<ListCustomerLanguagesRequest, ListCustomerLanguagesResponse>;
    /**
     * @generated from protobuf rpc: DeleteCustomerGlobalCustomLanguages(DeleteCustomerGlobalCustomLanguagesRequest) returns (google.protobuf.Empty);
     */
    deleteCustomerGlobalCustomLanguages(input: DeleteCustomerGlobalCustomLanguagesRequest, options?: RpcOptions): UnaryCall<DeleteCustomerGlobalCustomLanguagesRequest, Empty>;
    /**
     * @generated from protobuf rpc: RetrieveCustomerGlobalCustomLanguages(RetrieveCustomerGlobalCustomLanguagesRequest) returns (RetrieveCustomerGlobalCustomLanguagesResponse);
     */
    retrieveCustomerGlobalCustomLanguages(input: RetrieveCustomerGlobalCustomLanguagesRequest, options?: RpcOptions): UnaryCall<RetrieveCustomerGlobalCustomLanguagesRequest, RetrieveCustomerGlobalCustomLanguagesResponse>;
    /**
     * @generated from protobuf rpc: BulkRetrieveCustomerGlobalCustomLanguages(BulkRetrieveCustomerGlobalCustomLanguagesRequest) returns (BulkRetrieveCustomerGlobalCustomLanguagesResponse);
     */
    bulkRetrieveCustomerGlobalCustomLanguages(input: BulkRetrieveCustomerGlobalCustomLanguagesRequest, options?: RpcOptions): UnaryCall<BulkRetrieveCustomerGlobalCustomLanguagesRequest, BulkRetrieveCustomerGlobalCustomLanguagesResponse>;
    /**
     * @generated from protobuf rpc: UpdateCustomerGlobalCustomLanguages(UpdateCustomerGlobalCustomLanguagesRequest) returns (UpdateCustomerGlobalCustomLanguagesResponse);
     */
    updateCustomerGlobalCustomLanguages(input: UpdateCustomerGlobalCustomLanguagesRequest, options?: RpcOptions): UnaryCall<UpdateCustomerGlobalCustomLanguagesRequest, UpdateCustomerGlobalCustomLanguagesResponse>;
    /**
     * @generated from protobuf rpc: SyncCustomLanguageFields(SyncCustomLanguageFieldsRequest) returns (google.protobuf.Empty);
     */
    syncCustomLanguageFields(input: SyncCustomLanguageFieldsRequest, options?: RpcOptions): UnaryCall<SyncCustomLanguageFieldsRequest, Empty>;
    /**
     * OnLevelSearch returns search result for all child elements for the root element by id
     *
     * @generated from protobuf rpc: OnLevelSearch(OnLevelSearchRequest) returns (OnLevelSearchResponse);
     */
    onLevelSearch(input: OnLevelSearchRequest, options?: RpcOptions): UnaryCall<OnLevelSearchRequest, OnLevelSearchResponse>;
    /**
     * @generated from protobuf rpc: Sync(google.protobuf.Empty) returns (google.protobuf.Empty);
     */
    sync(input: Empty, options?: RpcOptions): UnaryCall<Empty, Empty>;
    /**
     * SyncFloorMapMetadata send pusher event to all WFX devices that connected to map
     *
     * @generated from protobuf rpc: SyncFloorMapMetadata(SyncFloorMapMetadataRequest) returns (SyncFloorMapMetadataResponse);
     */
    syncFloorMapMetadata(input: SyncFloorMapMetadataRequest, options?: RpcOptions): UnaryCall<SyncFloorMapMetadataRequest, SyncFloorMapMetadataResponse>;
    /**
     * @generated from protobuf rpc: GetMapEntityFloorID(GetMapEntityFloorIDRequest) returns (GetMapEntityFloorIDResponse);
     */
    getMapEntityFloorID(input: GetMapEntityFloorIDRequest, options?: RpcOptions): UnaryCall<GetMapEntityFloorIDRequest, GetMapEntityFloorIDResponse>;
    /**
     * UpdateRoomCalIntegrationStatus - manage integration status for all connected rooms.
     * Use this method for case when calendar auth have any issue and we need to notify any
     *
     * @generated from protobuf rpc: UpdateRoomCalIntegrationStatus(UpdateRoomCalIntegrationStatusRequest) returns (UpdateRoomCalIntegrationStatusResponse);
     */
    updateRoomCalIntegrationStatus(input: UpdateRoomCalIntegrationStatusRequest, options?: RpcOptions): UnaryCall<UpdateRoomCalIntegrationStatusRequest, UpdateRoomCalIntegrationStatusResponse>;
    /**
     * GetDefaultLanguage - get default language for rdx/rfx
     *
     * @generated from protobuf rpc: DefaultLanguage(GetDefaultLanguageRequest) returns (GetDefaultLanguageResponse);
     */
    defaultLanguage(input: GetDefaultLanguageRequest, options?: RpcOptions): UnaryCall<GetDefaultLanguageRequest, GetDefaultLanguageResponse>;
    /**
     * CreateRoomTemplate - create new room template record
     *
     * @generated from protobuf rpc: CreateRoomTemplate(CreateRoomTemplateRequest) returns (RoomTemplate);
     */
    createRoomTemplate(input: CreateRoomTemplateRequest, options?: RpcOptions): UnaryCall<CreateRoomTemplateRequest, RoomTemplate>;
    /**
     * ListRoomTemplates - return all available room templates for customer
     *
     * @generated from protobuf rpc: ListRoomTemplates(ListRoomTemplatesRequest) returns (RoomTemplatesResponse);
     */
    listRoomTemplates(input: ListRoomTemplatesRequest, options?: RpcOptions): UnaryCall<ListRoomTemplatesRequest, RoomTemplatesResponse>;
    /**
     * UpdateRoomTemplate - update room template record
     *
     * @generated from protobuf rpc: UpdateRoomTemplate(UpdateRoomTemplateRequest) returns (RoomTemplate);
     */
    updateRoomTemplate(input: UpdateRoomTemplateRequest, options?: RpcOptions): UnaryCall<UpdateRoomTemplateRequest, RoomTemplate>;
    /**
     * DeleteRoomTemplate - remove room template by id
     *
     * @generated from protobuf rpc: DeleteRoomTemplate(DeleteRoomTemplateRequest) returns (google.protobuf.Empty);
     */
    deleteRoomTemplate(input: DeleteRoomTemplateRequest, options?: RpcOptions): UnaryCall<DeleteRoomTemplateRequest, Empty>;
    /**
     * ApplyRoomTemplate - apply room template with selected fields to selected rooms
     *
     * @generated from protobuf rpc: ApplyRoomTemplate(ApplyRoomTemplateRequest) returns (ApplyRoomTemplateResponse);
     */
    applyRoomTemplate(input: ApplyRoomTemplateRequest, options?: RpcOptions): UnaryCall<ApplyRoomTemplateRequest, ApplyRoomTemplateResponse>;
    /**
     * CreateDeskTemplate - create new desk template record
     *
     * @generated from protobuf rpc: CreateDeskTemplate(CreateDeskTemplateRequest) returns (DeskTemplate);
     */
    createDeskTemplate(input: CreateDeskTemplateRequest, options?: RpcOptions): UnaryCall<CreateDeskTemplateRequest, DeskTemplate>;
    /**
     * ListDeskTemplates - return all available desk templates for customer
     *
     * @generated from protobuf rpc: ListDeskTemplates(ListDeskTemplatesRequest) returns (DeskTemplatesResponse);
     */
    listDeskTemplates(input: ListDeskTemplatesRequest, options?: RpcOptions): UnaryCall<ListDeskTemplatesRequest, DeskTemplatesResponse>;
    /**
     * UpdateDeskTemplate - update desk template record
     *
     * @generated from protobuf rpc: UpdateDeskTemplate(UpdateDeskTemplateRequest) returns (DeskTemplate);
     */
    updateDeskTemplate(input: UpdateDeskTemplateRequest, options?: RpcOptions): UnaryCall<UpdateDeskTemplateRequest, DeskTemplate>;
    /**
     * DeleteDeskTemplate - remove desk template by id
     *
     * @generated from protobuf rpc: DeleteDeskTemplate(DeleteDeskTemplateRequest) returns (google.protobuf.Empty);
     */
    deleteDeskTemplate(input: DeleteDeskTemplateRequest, options?: RpcOptions): UnaryCall<DeleteDeskTemplateRequest, Empty>;
    /**
     * ApplyDeskTemplate - apply desk template with selected fields to selected desks
     *
     * @generated from protobuf rpc: ApplyDeskTemplate(ApplyDeskTemplateRequest) returns (ApplyDeskTemplateResponse);
     */
    applyDeskTemplate(input: ApplyDeskTemplateRequest, options?: RpcOptions): UnaryCall<ApplyDeskTemplateRequest, ApplyDeskTemplateResponse>;
    /**
     * @generated from protobuf rpc: ExportDevices(google.protobuf.Empty) returns (google.protobuf.Empty);
     */
    exportDevices(input: Empty, options?: RpcOptions): UnaryCall<Empty, Empty>;
    /**
     * @generated from protobuf rpc: CreateBookingDashboard(CreateBookingDashboardRequest) returns (BookingDashboardResponse);
     */
    createBookingDashboard(input: CreateBookingDashboardRequest, options?: RpcOptions): UnaryCall<CreateBookingDashboardRequest, BookingDashboardResponse>;
    /**
     * @generated from protobuf rpc: GetBookingDashboard(GetBookingDashboardRequest) returns (BookingDashboardResponse);
     */
    getBookingDashboard(input: GetBookingDashboardRequest, options?: RpcOptions): UnaryCall<GetBookingDashboardRequest, BookingDashboardResponse>;
    /**
     * @generated from protobuf rpc: DeleteBookingDashboardItem(DeleteBookingDashboardItemRequest) returns (google.protobuf.Empty);
     */
    deleteBookingDashboardItem(input: DeleteBookingDashboardItemRequest, options?: RpcOptions): UnaryCall<DeleteBookingDashboardItemRequest, Empty>;
    /**
     * @generated from protobuf rpc: CreateInterestedWorkplaces(CreateInterestedWorkplacesRequest) returns (CreateInterestedWorkplacesResponse);
     */
    createInterestedWorkplaces(input: CreateInterestedWorkplacesRequest, options?: RpcOptions): UnaryCall<CreateInterestedWorkplacesRequest, CreateInterestedWorkplacesResponse>;
    /**
     * @generated from protobuf rpc: DeleteInterestedWorkplaces(DeleteInterestedWorkplacesRequest) returns (google.protobuf.Empty);
     */
    deleteInterestedWorkplaces(input: DeleteInterestedWorkplacesRequest, options?: RpcOptions): UnaryCall<DeleteInterestedWorkplacesRequest, Empty>;
    /**
     * Device section
     *
     * @generated from protobuf rpc: UpdateRDXDeviceLEDStatus(UpdateRDXDeviceLEDStatusRequest) returns (BasicRDXDeviceResponse);
     */
    updateRDXDeviceLEDStatus(input: UpdateRDXDeviceLEDStatusRequest, options?: RpcOptions): UnaryCall<UpdateRDXDeviceLEDStatusRequest, BasicRDXDeviceResponse>;
    /**
     * @generated from protobuf rpc: GetRDXDevice(GetRDXDeviceRequest) returns (BasicRDXDeviceResponse);
     */
    getRDXDevice(input: GetRDXDeviceRequest, options?: RpcOptions): UnaryCall<GetRDXDeviceRequest, BasicRDXDeviceResponse>;
    /**
     * @generated from protobuf rpc: DeleteRDXDevice(DeleteRDXDeviceRequest) returns (BasicRDXDeviceResponse);
     */
    deleteRDXDevice(input: DeleteRDXDeviceRequest, options?: RpcOptions): UnaryCall<DeleteRDXDeviceRequest, BasicRDXDeviceResponse>;
    /**
     * @generated from protobuf rpc: GetRDXDeviceRoom(GetRDXDeviceRoomRequest) returns (GetRDXDeviceRoomResponse);
     */
    getRDXDeviceRoom(input: GetRDXDeviceRoomRequest, options?: RpcOptions): UnaryCall<GetRDXDeviceRoomRequest, GetRDXDeviceRoomResponse>;
    /**
     * @generated from protobuf rpc: UpdateRDXRoomDisplaySettings(UpdateRDXRoomDisplaySettingsRequest) returns (UpdateRDXRoomDisplaySettingsResponse);
     */
    updateRDXRoomDisplaySettings(input: UpdateRDXRoomDisplaySettingsRequest, options?: RpcOptions): UnaryCall<UpdateRDXRoomDisplaySettingsRequest, UpdateRDXRoomDisplaySettingsResponse>;
    /**
     * @generated from protobuf rpc: UpdateRDXDevice(UpdateRDXDeviceRequest) returns (BasicRDXDeviceResponse);
     */
    updateRDXDevice(input: UpdateRDXDeviceRequest, options?: RpcOptions): UnaryCall<UpdateRDXDeviceRequest, BasicRDXDeviceResponse>;
}
/**
 * @generated from protobuf service Workplaces
 */
export class WorkplacesClient implements IWorkplacesClient, ServiceInfo {
    typeName = Workplaces.typeName;
    methods = Workplaces.methods;
    options = Workplaces.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * Send a GraphQL query and get a raw json response
     * Current operation names supported:
     * * addDeviceMutation
     * * unpairDeviceMutation
     * * updateDisplaySettings
     *
     * @generated from protobuf rpc: WorkplaceQuery(WorkplaceQueryRequest) returns (WorkplaceQueryResponse);
     */
    workplaceQuery(input: WorkplaceQueryRequest, options?: RpcOptions): UnaryCall<WorkplaceQueryRequest, WorkplaceQueryResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<WorkplaceQueryRequest, WorkplaceQueryResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteWorkplace(DeleteWorkplaceRequest) returns (google.protobuf.Empty);
     */
    deleteWorkplace(input: DeleteWorkplaceRequest, options?: RpcOptions): UnaryCall<DeleteWorkplaceRequest, Empty> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteWorkplaceRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * Get all rooms for calendar provider. Takes the customerID and calendarProviderId as query parameters.
     *
     * @generated from protobuf rpc: CalendarRooms(CalendarRoomsRequest) returns (CalendarRoomsResponse);
     */
    calendarRooms(input: CalendarRoomsRequest, options?: RpcOptions): UnaryCall<CalendarRoomsRequest, CalendarRoomsResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<CalendarRoomsRequest, CalendarRoomsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListNearbyRooms(NearbyRoomsRequest) returns (NearbyRoomsResponse);
     */
    listNearbyRooms(input: NearbyRoomsRequest, options?: RpcOptions): UnaryCall<NearbyRoomsRequest, NearbyRoomsResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<NearbyRoomsRequest, NearbyRoomsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * When deleting a calendar provider, this method is used to decouple rooms from calendars and
     * transfer paired devices to lobby mode
     *
     * @generated from protobuf rpc: DisconnectCalendarProvider(CalendarRoomsRequest) returns (DisconnectCalendarProviderResponse);
     */
    disconnectCalendarProvider(input: CalendarRoomsRequest, options?: RpcOptions): UnaryCall<CalendarRoomsRequest, DisconnectCalendarProviderResponse> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<CalendarRoomsRequest, DisconnectCalendarProviderResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * List available rooms matching the filters and datetimes. Returns a list of available rooms.
     *
     * @generated from protobuf rpc: ListAvailableRooms(ListAvailableRoomsRequest) returns (AvailableRoomsResponse);
     */
    listAvailableRooms(input: ListAvailableRoomsRequest, options?: RpcOptions): UnaryCall<ListAvailableRoomsRequest, AvailableRoomsResponse> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListAvailableRoomsRequest, AvailableRoomsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * List available desks return all desks under location that available for booking process.
     *
     * @generated from protobuf rpc: ListAvailableDesks(ListAvailableDesksRequest) returns (ListAvailableDesksResponse);
     */
    listAvailableDesks(input: ListAvailableDesksRequest, options?: RpcOptions): UnaryCall<ListAvailableDesksRequest, ListAvailableDesksResponse> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListAvailableDesksRequest, ListAvailableDesksResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * List all tags for all rooms under the set location. Returns a string list of tags.
     *
     * @generated from protobuf rpc: ListTags(ListTagsRequest) returns (Tags);
     */
    listTags(input: ListTagsRequest, options?: RpcOptions): UnaryCall<ListTagsRequest, Tags> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListTagsRequest, Tags>("unary", this._transport, method, opt, input);
    }
    /**
     * SearchDesks return all desks down in hierarchy for location.
     *
     * @generated from protobuf rpc: SearchDesks(SearchRequest) returns (SearchDesksResponse);
     */
    searchDesks(input: SearchRequest, options?: RpcOptions): UnaryCall<SearchRequest, SearchDesksResponse> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchRequest, SearchDesksResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * SearchAccountDesks return returns all desks assigned to the account
     *
     * @generated from protobuf rpc: SearchAccountDesks(SearchAccountDesksRequest) returns (DesksListResponse);
     */
    searchAccountDesks(input: SearchAccountDesksRequest, options?: RpcOptions): UnaryCall<SearchAccountDesksRequest, DesksListResponse> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchAccountDesksRequest, DesksListResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * SearchRooms return all rooms down in hierarchy for location.
     *
     * @generated from protobuf rpc: SearchRooms(SearchRequest) returns (RoomsInfoResponse);
     */
    searchRooms(input: SearchRequest, options?: RpcOptions): UnaryCall<SearchRequest, RoomsInfoResponse> {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchRequest, RoomsInfoResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * LocationPath return path from root to node.
     *
     * @generated from protobuf rpc: LocationPath(SearchRequest) returns (LocationPathResponse);
     */
    locationPath(input: SearchRequest, options?: RpcOptions): UnaryCall<SearchRequest, LocationPathResponse> {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchRequest, LocationPathResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * BatchLocationPath return path from root to node for requested items.
     * Note: locations should be with the same type
     *
     * @generated from protobuf rpc: BatchLocationPath(BatchSearchRequest) returns (BatchLocationPathResponse);
     */
    batchLocationPath(input: BatchSearchRequest, options?: RpcOptions): UnaryCall<BatchSearchRequest, BatchLocationPathResponse> {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept<BatchSearchRequest, BatchLocationPathResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * DesksInfo extract desks info from db
     *
     * @generated from protobuf rpc: DesksInfo(DesksInfoRequest) returns (DesksListResponse);
     */
    desksInfo(input: DesksInfoRequest, options?: RpcOptions): UnaryCall<DesksInfoRequest, DesksListResponse> {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept<DesksInfoRequest, DesksListResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * SameLevelDesks return desks that at the same level as target desk
     *
     * @generated from protobuf rpc: SameLevelDesks(SameLevelDesksRequest) returns (DesksListResponse);
     */
    sameLevelDesks(input: SameLevelDesksRequest, options?: RpcOptions): UnaryCall<SameLevelDesksRequest, DesksListResponse> {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept<SameLevelDesksRequest, DesksListResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * RoomsInfo extract rooms info from db
     *
     * @generated from protobuf rpc: RoomsInfo(RoomsInfoRequest) returns (RoomsInfoResponse);
     */
    roomsInfo(input: RoomsInfoRequest, options?: RpcOptions): UnaryCall<RoomsInfoRequest, RoomsInfoResponse> {
        const method = this.methods[15], opt = this._transport.mergeOptions(options);
        return stackIntercept<RoomsInfoRequest, RoomsInfoResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetRoom(GetRoomRequest) returns (GetRoomResponse);
     */
    getRoom(input: GetRoomRequest, options?: RpcOptions): UnaryCall<GetRoomRequest, GetRoomResponse> {
        const method = this.methods[16], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetRoomRequest, GetRoomResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListRooms(ListRoomsRequest) returns (ListRoomsResponse);
     */
    listRooms(input: ListRoomsRequest, options?: RpcOptions): UnaryCall<ListRoomsRequest, ListRoomsResponse> {
        const method = this.methods[17], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListRoomsRequest, ListRoomsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CustomerActiveRooms(CustomerActiveRoomsRequest) returns (CustomerActiveRoomsResponse);
     */
    customerActiveRooms(input: CustomerActiveRoomsRequest, options?: RpcOptions): UnaryCall<CustomerActiveRoomsRequest, CustomerActiveRoomsResponse> {
        const method = this.methods[18], opt = this._transport.mergeOptions(options);
        return stackIntercept<CustomerActiveRoomsRequest, CustomerActiveRoomsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UploadMap(MapRequest) returns (MapResponse);
     */
    uploadMap(input: MapRequest, options?: RpcOptions): UnaryCall<MapRequest, MapResponse> {
        const method = this.methods[19], opt = this._transport.mergeOptions(options);
        return stackIntercept<MapRequest, MapResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteMap(DeleteMapRequest) returns (google.protobuf.Empty);
     */
    deleteMap(input: DeleteMapRequest, options?: RpcOptions): UnaryCall<DeleteMapRequest, Empty> {
        const method = this.methods[20], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteMapRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * GenerateQRCode return qr code base64 string for workplace entity
     * Support only room or desk entity type
     *
     * @generated from protobuf rpc: GenerateQRCode(GenerateQRCodeRequest) returns (GenerateQRCodeResponse);
     */
    generateQRCode(input: GenerateQRCodeRequest, options?: RpcOptions): UnaryCall<GenerateQRCodeRequest, GenerateQRCodeResponse> {
        const method = this.methods[21], opt = this._transport.mergeOptions(options);
        return stackIntercept<GenerateQRCodeRequest, GenerateQRCodeResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * GetDesk return info for desk with free time slots
     *
     * @generated from protobuf rpc: GetDesk(GetDeskRequest) returns (GetDeskResponse);
     */
    getDesk(input: GetDeskRequest, options?: RpcOptions): UnaryCall<GetDeskRequest, GetDeskResponse> {
        const method = this.methods[22], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetDeskRequest, GetDeskResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * FloorMap return all rooms and desks in a floor with their free/busy status
     *
     * @generated from protobuf rpc: FloorMap(FloorMapRequest) returns (FloorMapResponse);
     */
    floorMap(input: FloorMapRequest, options?: RpcOptions): UnaryCall<FloorMapRequest, FloorMapResponse> {
        const method = this.methods[23], opt = this._transport.mergeOptions(options);
        return stackIntercept<FloorMapRequest, FloorMapResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * FloorMap return initial flor map without rooms or desks statuses
     *
     * @generated from protobuf rpc: FloorMapMetadata(FloorMapMetadataRequest) returns (FloorMapResponse);
     */
    floorMapMetadata(input: FloorMapMetadataRequest, options?: RpcOptions): UnaryCall<FloorMapMetadataRequest, FloorMapResponse> {
        const method = this.methods[24], opt = this._transport.mergeOptions(options);
        return stackIntercept<FloorMapMetadataRequest, FloorMapResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * RFXFloorMapMetadata return initial flor map for RFX without rooms or desks statuses
     *
     * @generated from protobuf rpc: RFXFloorMapMetadata(RFXFloorMapMetadataRequest) returns (FloorMapResponse);
     */
    rFXFloorMapMetadata(input: RFXFloorMapMetadataRequest, options?: RpcOptions): UnaryCall<RFXFloorMapMetadataRequest, FloorMapResponse> {
        const method = this.methods[25], opt = this._transport.mergeOptions(options);
        return stackIntercept<RFXFloorMapMetadataRequest, FloorMapResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * RDXFloorMapMetadata return floor map with connected room, nearby rooms and desks
     *
     * @generated from protobuf rpc: RDXFloorMap(RDXFloorMapRequest) returns (FloorMapResponse);
     */
    rDXFloorMap(input: RDXFloorMapRequest, options?: RpcOptions): UnaryCall<RDXFloorMapRequest, FloorMapResponse> {
        const method = this.methods[26], opt = this._transport.mergeOptions(options);
        return stackIntercept<RDXFloorMapRequest, FloorMapResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * DesksBulkCreate
     *
     * @generated from protobuf rpc: DesksBulkCreate(DesksBulkCreateRequest) returns (DesksBulkCreateResponse);
     */
    desksBulkCreate(input: DesksBulkCreateRequest, options?: RpcOptions): UnaryCall<DesksBulkCreateRequest, DesksBulkCreateResponse> {
        const method = this.methods[27], opt = this._transport.mergeOptions(options);
        return stackIntercept<DesksBulkCreateRequest, DesksBulkCreateResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * UpdateGlobalWorkingHrs is a method for update global working hrs records
     *
     * @generated from protobuf rpc: UpdateGlobalWorkingHrs(UpdateGlobalWorkingHrsRequest) returns (UpdateGlobalWorkingHrsResponse);
     */
    updateGlobalWorkingHrs(input: UpdateGlobalWorkingHrsRequest, options?: RpcOptions): UnaryCall<UpdateGlobalWorkingHrsRequest, UpdateGlobalWorkingHrsResponse> {
        const method = this.methods[28], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateGlobalWorkingHrsRequest, UpdateGlobalWorkingHrsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * SendErrorReport is a method for creating rdx error reporting
     *
     * @generated from protobuf rpc: SendErrorReport(SendErrorReportRequest) returns (google.protobuf.Empty);
     */
    sendErrorReport(input: SendErrorReportRequest, options?: RpcOptions): UnaryCall<SendErrorReportRequest, Empty> {
        const method = this.methods[29], opt = this._transport.mergeOptions(options);
        return stackIntercept<SendErrorReportRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * SetDevicePingInfo set date for device ping datetime
     *
     * @generated from protobuf rpc: SetDevicePingInfo(SetDevicePingInfoRequest) returns (google.protobuf.Empty);
     */
    setDevicePingInfo(input: SetDevicePingInfoRequest, options?: RpcOptions): UnaryCall<SetDevicePingInfoRequest, Empty> {
        const method = this.methods[30], opt = this._transport.mergeOptions(options);
        return stackIntercept<SetDevicePingInfoRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetConnectedFloorMap(GetConnectedFloorMapRequest) returns (GetConnectedFloorMapResponse);
     */
    getConnectedFloorMap(input: GetConnectedFloorMapRequest, options?: RpcOptions): UnaryCall<GetConnectedFloorMapRequest, GetConnectedFloorMapResponse> {
        const method = this.methods[31], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetConnectedFloorMapRequest, GetConnectedFloorMapResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UploadCustomLanguage(UploadCustomLanguageRequest) returns (UploadCustomLanguageResponse);
     */
    uploadCustomLanguage(input: UploadCustomLanguageRequest, options?: RpcOptions): UnaryCall<UploadCustomLanguageRequest, UploadCustomLanguageResponse> {
        const method = this.methods[32], opt = this._transport.mergeOptions(options);
        return stackIntercept<UploadCustomLanguageRequest, UploadCustomLanguageResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteCustomLanguage(DeleteCustomLanguageRequest) returns (google.protobuf.Empty);
     */
    deleteCustomLanguage(input: DeleteCustomLanguageRequest, options?: RpcOptions): UnaryCall<DeleteCustomLanguageRequest, Empty> {
        const method = this.methods[33], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteCustomLanguageRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CustomLanguage(GetCustomLanguageRequest) returns (CustomLanguageResponse);
     */
    customLanguage(input: GetCustomLanguageRequest, options?: RpcOptions): UnaryCall<GetCustomLanguageRequest, CustomLanguageResponse> {
        const method = this.methods[34], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetCustomLanguageRequest, CustomLanguageResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SyncDisplaySettingsCustomerID(google.protobuf.Empty) returns (google.protobuf.Empty);
     */
    syncDisplaySettingsCustomerID(input: Empty, options?: RpcOptions): UnaryCall<Empty, Empty> {
        const method = this.methods[35], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CheckDeviceVersion(CheckDeviceVersionRequest) returns (CheckDeviceVersionResponse);
     */
    checkDeviceVersion(input: CheckDeviceVersionRequest, options?: RpcOptions): UnaryCall<CheckDeviceVersionRequest, CheckDeviceVersionResponse> {
        const method = this.methods[36], opt = this._transport.mergeOptions(options);
        return stackIntercept<CheckDeviceVersionRequest, CheckDeviceVersionResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateDeviceApp(UpdateDeviceAppRequest) returns (google.protobuf.Empty);
     */
    updateDeviceApp(input: UpdateDeviceAppRequest, options?: RpcOptions): UnaryCall<UpdateDeviceAppRequest, Empty> {
        const method = this.methods[37], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateDeviceAppRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateCustomerGlobalCustomLanguages(CreateCustomerGlobalCustomLanguagesRequest) returns (google.protobuf.Empty);
     */
    createCustomerGlobalCustomLanguages(input: CreateCustomerGlobalCustomLanguagesRequest, options?: RpcOptions): UnaryCall<CreateCustomerGlobalCustomLanguagesRequest, Empty> {
        const method = this.methods[38], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateCustomerGlobalCustomLanguagesRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListCustomerLanguages(ListCustomerLanguagesRequest) returns (ListCustomerLanguagesResponse);
     */
    listCustomerLanguages(input: ListCustomerLanguagesRequest, options?: RpcOptions): UnaryCall<ListCustomerLanguagesRequest, ListCustomerLanguagesResponse> {
        const method = this.methods[39], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListCustomerLanguagesRequest, ListCustomerLanguagesResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteCustomerGlobalCustomLanguages(DeleteCustomerGlobalCustomLanguagesRequest) returns (google.protobuf.Empty);
     */
    deleteCustomerGlobalCustomLanguages(input: DeleteCustomerGlobalCustomLanguagesRequest, options?: RpcOptions): UnaryCall<DeleteCustomerGlobalCustomLanguagesRequest, Empty> {
        const method = this.methods[40], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteCustomerGlobalCustomLanguagesRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: RetrieveCustomerGlobalCustomLanguages(RetrieveCustomerGlobalCustomLanguagesRequest) returns (RetrieveCustomerGlobalCustomLanguagesResponse);
     */
    retrieveCustomerGlobalCustomLanguages(input: RetrieveCustomerGlobalCustomLanguagesRequest, options?: RpcOptions): UnaryCall<RetrieveCustomerGlobalCustomLanguagesRequest, RetrieveCustomerGlobalCustomLanguagesResponse> {
        const method = this.methods[41], opt = this._transport.mergeOptions(options);
        return stackIntercept<RetrieveCustomerGlobalCustomLanguagesRequest, RetrieveCustomerGlobalCustomLanguagesResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BulkRetrieveCustomerGlobalCustomLanguages(BulkRetrieveCustomerGlobalCustomLanguagesRequest) returns (BulkRetrieveCustomerGlobalCustomLanguagesResponse);
     */
    bulkRetrieveCustomerGlobalCustomLanguages(input: BulkRetrieveCustomerGlobalCustomLanguagesRequest, options?: RpcOptions): UnaryCall<BulkRetrieveCustomerGlobalCustomLanguagesRequest, BulkRetrieveCustomerGlobalCustomLanguagesResponse> {
        const method = this.methods[42], opt = this._transport.mergeOptions(options);
        return stackIntercept<BulkRetrieveCustomerGlobalCustomLanguagesRequest, BulkRetrieveCustomerGlobalCustomLanguagesResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateCustomerGlobalCustomLanguages(UpdateCustomerGlobalCustomLanguagesRequest) returns (UpdateCustomerGlobalCustomLanguagesResponse);
     */
    updateCustomerGlobalCustomLanguages(input: UpdateCustomerGlobalCustomLanguagesRequest, options?: RpcOptions): UnaryCall<UpdateCustomerGlobalCustomLanguagesRequest, UpdateCustomerGlobalCustomLanguagesResponse> {
        const method = this.methods[43], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateCustomerGlobalCustomLanguagesRequest, UpdateCustomerGlobalCustomLanguagesResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SyncCustomLanguageFields(SyncCustomLanguageFieldsRequest) returns (google.protobuf.Empty);
     */
    syncCustomLanguageFields(input: SyncCustomLanguageFieldsRequest, options?: RpcOptions): UnaryCall<SyncCustomLanguageFieldsRequest, Empty> {
        const method = this.methods[44], opt = this._transport.mergeOptions(options);
        return stackIntercept<SyncCustomLanguageFieldsRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * OnLevelSearch returns search result for all child elements for the root element by id
     *
     * @generated from protobuf rpc: OnLevelSearch(OnLevelSearchRequest) returns (OnLevelSearchResponse);
     */
    onLevelSearch(input: OnLevelSearchRequest, options?: RpcOptions): UnaryCall<OnLevelSearchRequest, OnLevelSearchResponse> {
        const method = this.methods[45], opt = this._transport.mergeOptions(options);
        return stackIntercept<OnLevelSearchRequest, OnLevelSearchResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Sync(google.protobuf.Empty) returns (google.protobuf.Empty);
     */
    sync(input: Empty, options?: RpcOptions): UnaryCall<Empty, Empty> {
        const method = this.methods[46], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * SyncFloorMapMetadata send pusher event to all WFX devices that connected to map
     *
     * @generated from protobuf rpc: SyncFloorMapMetadata(SyncFloorMapMetadataRequest) returns (SyncFloorMapMetadataResponse);
     */
    syncFloorMapMetadata(input: SyncFloorMapMetadataRequest, options?: RpcOptions): UnaryCall<SyncFloorMapMetadataRequest, SyncFloorMapMetadataResponse> {
        const method = this.methods[47], opt = this._transport.mergeOptions(options);
        return stackIntercept<SyncFloorMapMetadataRequest, SyncFloorMapMetadataResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetMapEntityFloorID(GetMapEntityFloorIDRequest) returns (GetMapEntityFloorIDResponse);
     */
    getMapEntityFloorID(input: GetMapEntityFloorIDRequest, options?: RpcOptions): UnaryCall<GetMapEntityFloorIDRequest, GetMapEntityFloorIDResponse> {
        const method = this.methods[48], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetMapEntityFloorIDRequest, GetMapEntityFloorIDResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * UpdateRoomCalIntegrationStatus - manage integration status for all connected rooms.
     * Use this method for case when calendar auth have any issue and we need to notify any
     *
     * @generated from protobuf rpc: UpdateRoomCalIntegrationStatus(UpdateRoomCalIntegrationStatusRequest) returns (UpdateRoomCalIntegrationStatusResponse);
     */
    updateRoomCalIntegrationStatus(input: UpdateRoomCalIntegrationStatusRequest, options?: RpcOptions): UnaryCall<UpdateRoomCalIntegrationStatusRequest, UpdateRoomCalIntegrationStatusResponse> {
        const method = this.methods[49], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateRoomCalIntegrationStatusRequest, UpdateRoomCalIntegrationStatusResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * GetDefaultLanguage - get default language for rdx/rfx
     *
     * @generated from protobuf rpc: DefaultLanguage(GetDefaultLanguageRequest) returns (GetDefaultLanguageResponse);
     */
    defaultLanguage(input: GetDefaultLanguageRequest, options?: RpcOptions): UnaryCall<GetDefaultLanguageRequest, GetDefaultLanguageResponse> {
        const method = this.methods[50], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetDefaultLanguageRequest, GetDefaultLanguageResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * CreateRoomTemplate - create new room template record
     *
     * @generated from protobuf rpc: CreateRoomTemplate(CreateRoomTemplateRequest) returns (RoomTemplate);
     */
    createRoomTemplate(input: CreateRoomTemplateRequest, options?: RpcOptions): UnaryCall<CreateRoomTemplateRequest, RoomTemplate> {
        const method = this.methods[51], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateRoomTemplateRequest, RoomTemplate>("unary", this._transport, method, opt, input);
    }
    /**
     * ListRoomTemplates - return all available room templates for customer
     *
     * @generated from protobuf rpc: ListRoomTemplates(ListRoomTemplatesRequest) returns (RoomTemplatesResponse);
     */
    listRoomTemplates(input: ListRoomTemplatesRequest, options?: RpcOptions): UnaryCall<ListRoomTemplatesRequest, RoomTemplatesResponse> {
        const method = this.methods[52], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListRoomTemplatesRequest, RoomTemplatesResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * UpdateRoomTemplate - update room template record
     *
     * @generated from protobuf rpc: UpdateRoomTemplate(UpdateRoomTemplateRequest) returns (RoomTemplate);
     */
    updateRoomTemplate(input: UpdateRoomTemplateRequest, options?: RpcOptions): UnaryCall<UpdateRoomTemplateRequest, RoomTemplate> {
        const method = this.methods[53], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateRoomTemplateRequest, RoomTemplate>("unary", this._transport, method, opt, input);
    }
    /**
     * DeleteRoomTemplate - remove room template by id
     *
     * @generated from protobuf rpc: DeleteRoomTemplate(DeleteRoomTemplateRequest) returns (google.protobuf.Empty);
     */
    deleteRoomTemplate(input: DeleteRoomTemplateRequest, options?: RpcOptions): UnaryCall<DeleteRoomTemplateRequest, Empty> {
        const method = this.methods[54], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteRoomTemplateRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * ApplyRoomTemplate - apply room template with selected fields to selected rooms
     *
     * @generated from protobuf rpc: ApplyRoomTemplate(ApplyRoomTemplateRequest) returns (ApplyRoomTemplateResponse);
     */
    applyRoomTemplate(input: ApplyRoomTemplateRequest, options?: RpcOptions): UnaryCall<ApplyRoomTemplateRequest, ApplyRoomTemplateResponse> {
        const method = this.methods[55], opt = this._transport.mergeOptions(options);
        return stackIntercept<ApplyRoomTemplateRequest, ApplyRoomTemplateResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * CreateDeskTemplate - create new desk template record
     *
     * @generated from protobuf rpc: CreateDeskTemplate(CreateDeskTemplateRequest) returns (DeskTemplate);
     */
    createDeskTemplate(input: CreateDeskTemplateRequest, options?: RpcOptions): UnaryCall<CreateDeskTemplateRequest, DeskTemplate> {
        const method = this.methods[56], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateDeskTemplateRequest, DeskTemplate>("unary", this._transport, method, opt, input);
    }
    /**
     * ListDeskTemplates - return all available desk templates for customer
     *
     * @generated from protobuf rpc: ListDeskTemplates(ListDeskTemplatesRequest) returns (DeskTemplatesResponse);
     */
    listDeskTemplates(input: ListDeskTemplatesRequest, options?: RpcOptions): UnaryCall<ListDeskTemplatesRequest, DeskTemplatesResponse> {
        const method = this.methods[57], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListDeskTemplatesRequest, DeskTemplatesResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * UpdateDeskTemplate - update desk template record
     *
     * @generated from protobuf rpc: UpdateDeskTemplate(UpdateDeskTemplateRequest) returns (DeskTemplate);
     */
    updateDeskTemplate(input: UpdateDeskTemplateRequest, options?: RpcOptions): UnaryCall<UpdateDeskTemplateRequest, DeskTemplate> {
        const method = this.methods[58], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateDeskTemplateRequest, DeskTemplate>("unary", this._transport, method, opt, input);
    }
    /**
     * DeleteDeskTemplate - remove desk template by id
     *
     * @generated from protobuf rpc: DeleteDeskTemplate(DeleteDeskTemplateRequest) returns (google.protobuf.Empty);
     */
    deleteDeskTemplate(input: DeleteDeskTemplateRequest, options?: RpcOptions): UnaryCall<DeleteDeskTemplateRequest, Empty> {
        const method = this.methods[59], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteDeskTemplateRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * ApplyDeskTemplate - apply desk template with selected fields to selected desks
     *
     * @generated from protobuf rpc: ApplyDeskTemplate(ApplyDeskTemplateRequest) returns (ApplyDeskTemplateResponse);
     */
    applyDeskTemplate(input: ApplyDeskTemplateRequest, options?: RpcOptions): UnaryCall<ApplyDeskTemplateRequest, ApplyDeskTemplateResponse> {
        const method = this.methods[60], opt = this._transport.mergeOptions(options);
        return stackIntercept<ApplyDeskTemplateRequest, ApplyDeskTemplateResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ExportDevices(google.protobuf.Empty) returns (google.protobuf.Empty);
     */
    exportDevices(input: Empty, options?: RpcOptions): UnaryCall<Empty, Empty> {
        const method = this.methods[61], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateBookingDashboard(CreateBookingDashboardRequest) returns (BookingDashboardResponse);
     */
    createBookingDashboard(input: CreateBookingDashboardRequest, options?: RpcOptions): UnaryCall<CreateBookingDashboardRequest, BookingDashboardResponse> {
        const method = this.methods[62], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateBookingDashboardRequest, BookingDashboardResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetBookingDashboard(GetBookingDashboardRequest) returns (BookingDashboardResponse);
     */
    getBookingDashboard(input: GetBookingDashboardRequest, options?: RpcOptions): UnaryCall<GetBookingDashboardRequest, BookingDashboardResponse> {
        const method = this.methods[63], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetBookingDashboardRequest, BookingDashboardResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteBookingDashboardItem(DeleteBookingDashboardItemRequest) returns (google.protobuf.Empty);
     */
    deleteBookingDashboardItem(input: DeleteBookingDashboardItemRequest, options?: RpcOptions): UnaryCall<DeleteBookingDashboardItemRequest, Empty> {
        const method = this.methods[64], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteBookingDashboardItemRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateInterestedWorkplaces(CreateInterestedWorkplacesRequest) returns (CreateInterestedWorkplacesResponse);
     */
    createInterestedWorkplaces(input: CreateInterestedWorkplacesRequest, options?: RpcOptions): UnaryCall<CreateInterestedWorkplacesRequest, CreateInterestedWorkplacesResponse> {
        const method = this.methods[65], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateInterestedWorkplacesRequest, CreateInterestedWorkplacesResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteInterestedWorkplaces(DeleteInterestedWorkplacesRequest) returns (google.protobuf.Empty);
     */
    deleteInterestedWorkplaces(input: DeleteInterestedWorkplacesRequest, options?: RpcOptions): UnaryCall<DeleteInterestedWorkplacesRequest, Empty> {
        const method = this.methods[66], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteInterestedWorkplacesRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * Device section
     *
     * @generated from protobuf rpc: UpdateRDXDeviceLEDStatus(UpdateRDXDeviceLEDStatusRequest) returns (BasicRDXDeviceResponse);
     */
    updateRDXDeviceLEDStatus(input: UpdateRDXDeviceLEDStatusRequest, options?: RpcOptions): UnaryCall<UpdateRDXDeviceLEDStatusRequest, BasicRDXDeviceResponse> {
        const method = this.methods[67], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateRDXDeviceLEDStatusRequest, BasicRDXDeviceResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetRDXDevice(GetRDXDeviceRequest) returns (BasicRDXDeviceResponse);
     */
    getRDXDevice(input: GetRDXDeviceRequest, options?: RpcOptions): UnaryCall<GetRDXDeviceRequest, BasicRDXDeviceResponse> {
        const method = this.methods[68], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetRDXDeviceRequest, BasicRDXDeviceResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteRDXDevice(DeleteRDXDeviceRequest) returns (BasicRDXDeviceResponse);
     */
    deleteRDXDevice(input: DeleteRDXDeviceRequest, options?: RpcOptions): UnaryCall<DeleteRDXDeviceRequest, BasicRDXDeviceResponse> {
        const method = this.methods[69], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteRDXDeviceRequest, BasicRDXDeviceResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetRDXDeviceRoom(GetRDXDeviceRoomRequest) returns (GetRDXDeviceRoomResponse);
     */
    getRDXDeviceRoom(input: GetRDXDeviceRoomRequest, options?: RpcOptions): UnaryCall<GetRDXDeviceRoomRequest, GetRDXDeviceRoomResponse> {
        const method = this.methods[70], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetRDXDeviceRoomRequest, GetRDXDeviceRoomResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateRDXRoomDisplaySettings(UpdateRDXRoomDisplaySettingsRequest) returns (UpdateRDXRoomDisplaySettingsResponse);
     */
    updateRDXRoomDisplaySettings(input: UpdateRDXRoomDisplaySettingsRequest, options?: RpcOptions): UnaryCall<UpdateRDXRoomDisplaySettingsRequest, UpdateRDXRoomDisplaySettingsResponse> {
        const method = this.methods[71], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateRDXRoomDisplaySettingsRequest, UpdateRDXRoomDisplaySettingsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateRDXDevice(UpdateRDXDeviceRequest) returns (BasicRDXDeviceResponse);
     */
    updateRDXDevice(input: UpdateRDXDeviceRequest, options?: RpcOptions): UnaryCall<UpdateRDXDeviceRequest, BasicRDXDeviceResponse> {
        const method = this.methods[72], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateRDXDeviceRequest, BasicRDXDeviceResponse>("unary", this._transport, method, opt, input);
    }
}
