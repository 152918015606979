import React, { PropsWithChildren } from "react";
import { useInsightsContext } from "../../Context/InsightsContext";
import { useContacts } from "../../../../api/grpc/contacts/useContacts";
import { useCalendarContext } from "../../../../lib/context/Calendar/CalendarContext";
import { useRouter } from "../../../../lib/hooks/useRouter";
import useGrpcQuery from "../../../../lib/hooks/useGrpcQuery";
import { useCheckUserPermissions } from "../../../../lib/hooks/useCheckUserPermissions";

import { OrganizerFilter } from "../../Context/Room/Overview/RoomInsightsContextProvider";
import { HistoryOrganizers } from "./HistoryOrganizers";
import { Popup } from "../../../shared/Popup/Popup";
import { Icon } from "../../../shared/Icon/Icon";
import {
  ListPeopleFilter,
  ListPeopleRequest,
  People,
} from "../../../../api/grpc/contacts/contacts";
import { NavLink } from "react-router-dom";
import { RolePermissions } from "../../../../api/grpc/account/account";

export const HistoryFilters = ({
  disbaled,
  organizers,
  setOrganizers,
}: PropsWithChildren<{
  disbaled: boolean;
  organizers?: OrganizerFilter[];
  setOrganizers: React.Dispatch<
    React.SetStateAction<OrganizerFilter[] | undefined>
  >;
}>) => {
  const { listPeople } = useContacts();
  const { personalCalendar } = useCalendarContext();
  const { pathname } = useRouter();
  const { customerSettings } = useInsightsContext();
  const { checkUserPermission } = useCheckUserPermissions();

  const { loading, error, data } = useGrpcQuery<ListPeopleRequest, People>({
    method: listPeople,
    variables: {
      personalCalendarID: pathname.includes("desk")
        ? ""
        : personalCalendar[0]?.iD || "",
      customerID: "",
      query: "",
      accountID: "",
      filter: ListPeopleFilter.ALL,
      limit: 5,
    },
  });

  return (
    <Popup
      className={`HistoryFilters ${disbaled ? "disabled" : ""}`}
      trigger={() => (
        <div className="HistoryFilters flex-a-center">
          <Icon
            icon="filter-icon"
            className={organizers?.length ? "active-icon" : ""}
          ></Icon>
          <h4 className={organizers?.length ? "link" : ""}>
            Organizer {organizers?.length ? `(${organizers.length})` : ""}
          </h4>
        </div>
      )}
      position="bottom right"
      title="Spotlight people"
      disabled={disbaled}
    >
      {({ handleClose }) =>
        customerSettings?.showEventOrganizer ? (
          <HistoryOrganizers
            loading={loading}
            error={error}
            data={data}
            organizers={organizers}
            setOrganizers={setOrganizers}
            handleClose={handleClose}
            listPeople={listPeople}
            personalCalendarID={
              pathname.includes("desk") ? "" : personalCalendar[0]?.iD || ""
            }
          />
        ) : (
          <>
            <div>The show event organizer feature is disabled.</div>
            {checkUserPermission(
              RolePermissions.ORGANIZATION_SETTINGS_GENERAL_VIEW_EDIT
            ) ? (
              <NavLink to="/settings/organization">Enable it here</NavLink>
            ) : (
              <>Contact your admin to enable.</>
            )}
          </>
        )
      }
    </Popup>
  );
};
