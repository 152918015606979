import React, { PropsWithChildren } from "react";
import cs from "classnames";
import { trimText } from "../../../lib/helpers/trimText";

export const TooltipName = ({
  name,
  className,
  isBooked,
}: PropsWithChildren<{
  name: string;
  className?: string;
  isBooked?: boolean;
}>) => {
  return (
    <div>
      {" "}
      <svg width="155" height="43.75" viewBox="0 0 180 50">
        <text
          x="50%"
          y="40%"
          className={cs(className, {
            booked: isBooked,
          })}
        >
          {trimText(name, 18)}
        </text>
      </svg>
    </div>
  );
};
