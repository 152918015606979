import React, { useState } from "react";
import { useBookingsMapContext } from "../../BookingsMap/Context/BookingsMapContext";
import { FormGroup, Label } from "reactstrap";

import { Switch } from "../../../shared/Switch/Switch";
import {
  ListPeopleRequest,
  People,
} from "../../../../api/grpc/contacts/contacts";
import { SelectPeopleList } from "../FindPeopleButton/SelectPeopleList";
import { Input } from "../../../shared/Input/Input";
import { FinishedUnaryCall } from "@protobuf-ts/runtime-rpc";

interface Props {
  listPeople: (
    request: ListPeopleRequest
  ) => Promise<FinishedUnaryCall<ListPeopleRequest, People>>;
  personalCalendarID: string;
  loading: boolean;
  error: any;
  data?: People;
}

export const BookOnBehalf = ({
  data,
  error,
  listPeople,
  personalCalendarID,
}: Props) => {
  const [bookOnBehalf, setBookOnBehalf] = useState(false);
  const { bookOnBehalfUser, setBookOnBehalfUser, setNotifyAssignee } =
    useBookingsMapContext();

  return (
    <div className="BookOnBehalf SearchWorkplaceForm__switch mb-4">
      <Switch
        name="bookOnBehalf"
        title="Book on behalf of"
        value={bookOnBehalf}
        onChange={() => {
          if (bookOnBehalfUser) {
            setBookOnBehalfUser(undefined);
          }

          setBookOnBehalf((prev) => !prev);
        }}
      />

      <span className="text-14 text-secondary mb-1 font-weight-light lh-0">
        Adding a user will assign the reservation to them instead of you.
      </span>

      {bookOnBehalf && (
        <>
          <SelectPeopleList
            error={error}
            data={data}
            bookOnBehalf
            listPeople={listPeople}
            personalCalendarID={personalCalendarID}
          />

          <div className="mt-3 flex-center">
            <FormGroup className="flex-a-center Notify mb-0">
              <Input
                type="checkbox"
                id="notifyAssigne"
                name="notifyAssigne"
                data-cy="notifyAssigne"
                onChange={() => setNotifyAssignee((prev) => !prev)}
              />
              <Label for="notifyAssigne" className="checkbox_label">
                <p className="pr-1 font-weight-light">Notify assignee</p>
                <span className="checkbox_box"></span>
              </Label>
            </FormGroup>
          </div>
        </>
      )}
    </div>
  );
};
