import React from "react";
import cs from "classnames";
import { MarkerStatuses } from "../lib/useMarkerData";
import { Icon, IconDefinition } from "../../shared/Icon/Icon";

import "./MarkerStatus.scss";

interface Props {
  status: MarkerStatuses;
  nowAvailable?: boolean;
}

const getStatusDetails = (
  status: MarkerStatuses,
  nowAvailable?: boolean
): {
  title: string;
  icon: IconDefinition;
} => {
  switch (status) {
    case MarkerStatuses.ASSIGNED:
      return { title: "Assigned", icon: "block-icon" };
    case MarkerStatuses.BLOCKED:
      return { title: "Blocked", icon: "block-icon" };
    case MarkerStatuses.RESERVED:
      return { title: "Reserved", icon: "red-rounded" };
    default:
      return {
        title: nowAvailable ? "Now available" : "Available",
        icon: "green-rounded",
      };
  }
};

export const MarkerStatus = ({ status, nowAvailable }: Props) => {
  const { title, icon } = getStatusDetails(status, nowAvailable);

  const className = cs("MarkerStatus", {
    "MarkerStatus--assigned": status === MarkerStatuses.ASSIGNED,
    "MarkerStatus--blocked": status === MarkerStatuses.BLOCKED,
    "MarkerStatus--reserved": status === MarkerStatuses.RESERVED,
    "MarkerStatus--available": status === MarkerStatuses.AVAILABLE,
  });

  return (
    <div className={className}>
      <Icon icon={icon} />
      <span className="text-14 text-black">{title}</span>
    </div>
  );
};
