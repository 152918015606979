import L, { Direction, Map, Marker, PointExpression } from "leaflet";
import { initGeometryUtil } from "../plugins/leaflet/leaflet-geometry-util";
// import { initLeafletSnap } from "../plugins/leaflet/leaflet-snap";
import { Size } from "../../components/Floors/FloorPlan/FloorsPlan";
import { trimText } from "./trimText";

type LeafletIconParams = {
  svgUrl: string;
  size: [number, number];
  anchor?: [number, number];
  type: string;
};

type DrawMarkerParams = {
  map: Map;
  markers: L.Marker[];
  poiMarkers: L.Marker[];
  layers: L.Layer[];
  handleShapeClick: () => void;
  config: {
    mapSize: Size;
  };
  options: {
    snapEnabled: boolean;
    showTooltips: boolean;
    showRoomMarkers: boolean;
  };
  openMarkerId?: string;
};

const tooltipOffsetBase = 1; // Base offset for tooltips
const distanceThreshold = 60; // Minimum distance to check for other markers

export const createLeafletIcon = ({
  svgUrl,
  size,
  anchor,
  type,
}: LeafletIconParams): L.Icon => {
  return L.icon({
    iconSize: size,
    iconAnchor: anchor || [size[0] / 2, size[1] / 2],
    popupAnchor: [0, -size[1] / 2],
    iconUrl: svgUrl,
    attribution: type,
    shadowSize: [40, 50],
  });
};

export const getMarkerIndex = (marker: Marker): number => {
  // @ts-ignore
  return marker._index;
};

export const setMarkerIndex = (marker: Marker, index: number): void => {
  // @ts-ignore
  marker._index = index;
};

export const isMarkersOnOneLine = (
  latlngs: [L.LatLngLiteral, L.LatLngLiteral]
) => {
  const lngDifference = Math.abs(latlngs[0].lng - latlngs[1].lng);
  const latDifference = Math.abs(latlngs[0].lat - latlngs[1].lat);

  return lngDifference < 12 || latDifference < 12;
};

export const drawMarkers = ({
  map,
  markers,
  poiMarkers,
  layers,
  handleShapeClick,
  config,
  options,
  openMarkerId,
}: DrawMarkerParams) => {
  initGeometryUtil(L);
  // initLeafletSnap(L);

  layers.forEach((layer) => {
    layer.addTo(map);

    //show room's name in center of the shape, if users hide the room markers
    if (!options.showRoomMarkers && options.showTooltips) {
      const layerName = layer.options.attribution?.split(
        "-innerGoGetIDSplitter-"
      )[1];

      layer
        .bindTooltip(
          `<svg width="155" height="43.75" viewBox="0 0 180 50">
            <text x="50%" y="40%">
              ${trimText(layerName || "", 18)}
           </text>
          </svg>`,
          {
            permanent: true,
            direction: "center",
            opacity: 1,
            offset: [0, -7],
            className: `room-permanent-tooltip admin-tooltip`,
          }
        )
        .openTooltip();
    }

    layer.off("click");

    layer.on("click", () => {
      // handleShapeClick(layer, layer.options.attribution || "");
      handleShapeClick();
    });
  });

  poiMarkers.forEach((poiMarker) => {
    poiMarker.addTo(map);
  });

  markers.forEach((marker, index) => {
    marker.addTo(map);

    setMarkerIndex(marker, index);

    if (openMarkerId && marker.options.attribution === openMarkerId) {
      marker.openPopup();
    }

    if (
      options.showTooltips &&
      marker.options.alt === "Room" &&
      marker.getTooltip() === undefined
    ) {
      let direction: Direction = "bottom";
      let tooltipOffset: PointExpression = [tooltipOffsetBase, 0];

      const latlng = marker.getLatLng();
      const hasMarkerOnRight = markers.some(
        (otherMarker) =>
          otherMarker !== marker &&
          otherMarker.getLatLng().lng >= latlng.lng &&
          map.distance(latlng, otherMarker.getLatLng()) < distanceThreshold
      );
      const hasMarkerOnLeft = markers.some(
        (otherMarker) =>
          otherMarker !== marker &&
          otherMarker.getLatLng().lng <= latlng.lng &&
          map.distance(latlng, otherMarker.getLatLng()) < distanceThreshold
      );
      const hasMarkerOnTop = markers.some(
        (otherMarker) =>
          otherMarker !== marker &&
          otherMarker.getLatLng().lat >= latlng.lat &&
          map.distance(latlng, otherMarker.getLatLng()) < distanceThreshold
      );
      // const hasMarkerOnBottom = markers.some(
      //   (otherMarker) =>
      //     otherMarker !== marker &&
      //     otherMarker.getLatLng().lat <= latlng.lat &&
      //     map.distance(latlng, otherMarker.getLatLng()) < distanceThreshold
      // );

      if (!hasMarkerOnRight) {
        direction = "bottom";
        tooltipOffset = [tooltipOffsetBase, 0];
      } else if (!hasMarkerOnLeft) {
        direction = "left";
        tooltipOffset = [-tooltipOffsetBase, 0];
      } else if (!hasMarkerOnTop) {
        direction = "top";
        tooltipOffset = [0, -tooltipOffsetBase];
      }
      // else if (!hasMarkerOnBottom) {
      //   direction = "bottom";
      //   tooltipOffset = [0, tooltipOffsetBase];
      // }

      marker
        .bindTooltip(
          `<svg width="155" height="43.75" viewBox="0 0 180 50">
            <text x="50%" y="40%">
             ${trimText(marker.options.title || "", 18)}
            </text>
          </svg>`,
          {
            permanent: true,
            direction: direction,
            opacity: 1,
            offset: tooltipOffset,
            className: `room-permanent-tooltip admin-tooltip ${direction}`,
          }
        )
        .openTooltip();
    }
  });

  if (!options.snapEnabled) {
    return;
  }

  const snapGuide = L.polyline([], {
    interactive: false,
    weight: 2,
  }).addTo(map);
  console.log("snapline", snapGuide.getLatLngs());
  markers.forEach((marker) => {
    // @ts-ignore
    // marker.snapediting = new L.Handler.MarkerSnap(map, marker);
    // @ts-ignore
    // marker.snapediting.addGuideLayer(polyline);
    // @ts-ignore
    // marker.snapediting.enable();
    marker.on("dragstart", () => {
      console.log("dragstart", snapGuide.getLatLngs());

      // Ensure guide is cleared at start
      snapGuide.setLatLngs([]);
    });

    marker.on("drag", (e) => {
      const currentMarker = e.target;
      const currentLatLng = currentMarker.getLatLng();
      const alignedPositions: L.LatLng[][] = [];

      // Find aligned markers
      markers.forEach((otherMarker) => {
        if (otherMarker === currentMarker) return;

        const otherLatLng = otherMarker.getLatLng();
        const lngDiff = Math.abs(otherLatLng.lng - currentLatLng.lng);
        const latDiff = Math.abs(otherLatLng.lat - currentLatLng.lat);

        // Check alignment within threshold
        if (lngDiff < 0.5) {
          alignedPositions.push([
            new L.LatLng(-config.mapSize.height, otherLatLng.lng),
            new L.LatLng(config.mapSize.height, otherLatLng.lng),
          ]);
        } else if (latDiff < 0.5) {
          alignedPositions.push([
            new L.LatLng(otherLatLng.lat, -config.mapSize.width),
            new L.LatLng(otherLatLng.lat, config.mapSize.width),
          ]);
        }
      });

      // Update guide lines only if we have alignments
      snapGuide.setLatLngs(alignedPositions);
    });

    marker.on("dragend", () => {
      console.log("dragend", snapGuide.getLatLngs());
      // Clear guide lines immediately when dragging stops
      snapGuide.setLatLngs([]);
    });
  });
};
