import React, { useEffect, useState } from "react";
import { differenceInMinutes, endOfDay, isToday } from "date-fns";
import { toast } from "react-toastify";
import { useWorkplacesClient } from "../../../../api/grpc/workplaces/useWorkplacesClient";
import { useAuthContext } from "../../../../lib/context/Auth/AuthContext";
import { useLocationDefaultInfo } from "../../helpers/useLocationId";
import { useQuery } from "@apollo/client";
import { useBookingsContext } from "../../Context/BookingsContext";
import { useSearchRoomsFormik } from "../../lib/useSearchRoomsFormik";
import { useTimeZoneContext } from "../../../../lib/context/TimeZone/TimeZoneContext";
import { utcToZonedTime } from "date-fns-tz";
import { useCalendarContext } from "../../../../lib/context/Calendar/CalendarContext";
import { useBookingsMapContext } from "../../BookingsMap/Context/BookingsMapContext";
import { forReservationDate } from "../../lib/datePickerHelper";
import { useRouter } from "../../../../lib/hooks/useRouter";

import { LoadingBox } from "../../../shared/LoadingBox/LoadingBox";
import { SearchWorkplaceForm } from "../../form/SearchWorkplaceForm";
import {
  attachHoursToDate,
  dateToHoursAndMinutes,
} from "../../lib/dateInputConvert";
import { ReserveCard } from "../../Reserve/ReserveCard";
import { ReserveFooter } from "../ReserveFooter";
import { BookingsRoomsResult } from "./BookingsRoomsResult";
import { HandleRequestState } from "../../../shared/HandleRequestState/HandleRequestState";
import { CenteredBox } from "../../../shared/CenteredBox/CenteredBox";
import {
  GetDefaultLocationPathRequestVariables,
  GetDefaultLocationPathResponse,
  GET_DEFAULT_LOCATION_PATH,
} from "../../../../api/graphql/bookings/bookings";
import { Button } from "../../../shared/Button/Button";
import { AvailableRoom } from "../../../../api/grpc/workplaces/workplaces";
import { SearchHeaderInfo } from "../BookingsSearchHeader/BookingsSearchHeader";
import {
  ERROR_STRINGS,
  MINIMAL_BOOKING_INTERVAL,
} from "../../../../lib/utils/constants";

interface Props {
  type: string;
}

export const BookingsRoomsSearch = ({ type }: Props) => {
  const [loading, setLoading] = useState(false);
  const { timeZone } = useTimeZoneContext();
  const { user } = useAuthContext();
  const { listAvailableRooms } = useWorkplacesClient();
  const { history } = useRouter();
  const [pagePagination, setPagePagination] = useState(1);
  const { allDay, setAllDay } = useBookingsMapContext();
  const {
    matchedRooms,
    handleMatchedRooms,
    seats,
    equipment,
    subject,
    handleSubject,
    activeTagsFilter,
    location: { locationName },
  } = useBookingsContext();
  const { personalCalendar } = useCalendarContext();
  const { _locationId } = useLocationDefaultInfo();
  const defaultLocation = useQuery<
    GetDefaultLocationPathResponse,
    GetDefaultLocationPathRequestVariables
  >(GET_DEFAULT_LOCATION_PATH, {
    fetchPolicy: "network-only",
    variables: {
      accountId: user?.claims.user_id || "",
    },
  });

  const [headerInfo, setHeaderInfo] = useState<SearchHeaderInfo>({
    time: "",
    day: "",
    location: "",
  });

  useEffect(() => {
    if (allDay === true) {
      setAllDay(false);
    }

    if (!!subject.length) {
      return handleSubject("");
    }
  }, []);

  const formik = useSearchRoomsFormik({
    onSubmit: async (values) => {
      if (!subject.length) {
        return;
      }

      if (!isToday(new Date(values.until)) && !allDay) {
        return toast.error(ERROR_STRINGS.endTimeNextDay);
      }

      try {
        setLoading(true);

        const timeDifference = differenceInMinutes(
          utcToZonedTime(new Date(), timeZone),
          values.from
        );

        const validTimePassed = timeDifference <= 5 && timeDifference > 0;

        let startTime = attachHoursToDate(
          values.day.value,
          validTimePassed ? utcToZonedTime(new Date(), timeZone) : values.from,
          timeZone
        );

        let endTime = attachHoursToDate(
          values.day.value,
          values.until,
          timeZone,
          allDay
        );

        if (differenceInMinutes(new Date(), new Date(startTime)) > 5) {
          setLoading(false);
          return toast.error("Invalid From time!");
        }

        if (
          !allDay &&
          differenceInMinutes(values.until, values.from) <
            MINIMAL_BOOKING_INTERVAL
        ) {
          setLoading(false);
          return toast.error(
            `Booking duration can't be less than ${MINIMAL_BOOKING_INTERVAL} minutes`
          );
        }

        //seat comes as a string for example "1-5", first val is min, second is max
        //if user choose +20, we manually add 20 and 1000 values
        let splitSeat = seats.includes("-") ? seats.split("-") : ["20", "1000"];

        let availableRooms: AvailableRoom[] = [];
        let pageFromResponse: number | null = null;

        do {
          const {
            response: { rooms, nextPage },
          }: { response: { rooms: AvailableRoom[]; nextPage: number } } =
            await listAvailableRooms({
              customerId: user?.customerid || "",
              locationId: _locationId || "",
              tags: activeTagsFilter || [],
              from: startTime,
              to: endTime,
              minNrSeats: seats.includes("Any") ? 0 : Number(splitSeat[0]),
              maxNrSeats: seats.includes("Any") ? 1000 : Number(splitSeat[1]),
              equipment: equipment,
              timeZone: timeZone,
              personalCalendarProviderId: personalCalendar[0]?.iD || "",
              page:
                pageFromResponse !== null ? pageFromResponse : pagePagination,
            });

          availableRooms = [...availableRooms, ...rooms];
          pageFromResponse = nextPage;
        } while (
          availableRooms.length < 8 &&
          pageFromResponse !== null &&
          pageFromResponse > 0
        );

        if (matchedRooms) {
          handleMatchedRooms([...matchedRooms, ...(availableRooms || [])]);
        } else {
          handleMatchedRooms(availableRooms);
        }

        setPagePagination(pageFromResponse ?? 1);
        const formattedDate = forReservationDate(
          utcToZonedTime(new Date(startTime), timeZone),
          timeZone
        );
        setHeaderInfo({
          day: formattedDate,
          location:
            locationName && locationName !== "RefreshedId"
              ? locationName
              : "All locations",
          time:
            dateToHoursAndMinutes(new Date(startTime), timeZone) +
            "-" +
            dateToHoursAndMinutes(new Date(endTime), timeZone),
        });

        if (window.innerWidth <= 1200) {
          const targetSection = document.querySelector(
            ".BookingsSearch__workplaces"
          );

          if (targetSection) {
            const offsetTop =
              targetSection.getBoundingClientRect().top + window.pageYOffset;

            window.scrollTo({ top: offsetTop, behavior: "smooth" });
          }
        }

        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        console.log(error);
        toast.error(error.message);
      }
    },
  });

  return (
    <div className="BookingsSearch__layout">
      <ReserveCard
        workplaceType={type}
        footer={
          <ReserveFooter
            onClick={() => {
              if (pagePagination !== 1) {
                //make new request instead of pagination request
                setPagePagination(1);
                handleMatchedRooms([]);
              }

              return formik.handleSubmit();
            }}
            disabled={defaultLocation.loading || loading}
          />
        }
        className="BookingsSearch__layout--search--rooms"
      >
        <HandleRequestState
          state={defaultLocation.data === undefined && !defaultLocation.loading}
          placeholder={<CenteredBox>Could't fetch location data</CenteredBox>}
        >
          <SearchWorkplaceForm
            formik={formik}
            type="room"
            defaultLocation={defaultLocation.data}
            loading={defaultLocation.loading}
          />
        </HandleRequestState>
      </ReserveCard>
      {/* only show loader for first request */}
      {loading && !!!matchedRooms?.length ? (
        <LoadingBox
          count={10}
          className="BookingsSearch__loading"
          minHeight={45}
        />
      ) : matchedRooms ? (
        <div className="BookingsSearch__workplaces">
          <BookingsRoomsResult rooms={matchedRooms} info={headerInfo} />

          {/* loader for new requests */}
          {loading && (
            <LoadingBox
              count={10}
              className="BookingsSearch__loading"
              minHeight={45}
            />
          )}
          {pagePagination > 1 && (
            <div className="flex-a-center BookingsSearch__layout--pagination">
              <Button
                color="outline-primary"
                title="Back"
                size="small"
                className="mt-4 BookingsSearch__layout--pagination--back"
                disabled={loading}
                onClick={() => {
                  return history.push("/bookings");
                }}
              />

              <Button
                color="primary"
                title="More items"
                size="small"
                className="mt-4"
                disabled={loading}
                loadSpinner={loading}
                loadTitle="Loading"
                onClick={() => {
                  formik.handleSubmit();
                }}
              />
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
