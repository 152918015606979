import React, { PropsWithChildren, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FormGroup, ModalBody } from "reactstrap";
import { getIn } from "formik";

import { useCustomWayfinderLanguageFormik } from "../../shared/Forms/DisplaySettingsForm/lib/useCustomWayfinderLanguageForm";
import { useCustomLanguageClient } from "../../../api/grpc/workplaces/useCustomLanguageClient";

import { formatKeyName } from "../../shared/Forms/DisplaySettingsForm/lib/formatLanguageKeyName";
import { FormSection } from "../../shared/FormSection/FormSection";
import { Input } from "../../shared/Input/Input";
import { LabelWithInformationBox } from "../../shared/LabelWithInformationBox/LabelWithInformationBox";
import { defualtWayfinderLanguage } from "../../shared/Forms/WayFinderForm/lib/defualtWayfinderLanguage";
import { InputDescription } from "../../shared/InputDescription/InputDescription";
import { ModalFooterContent } from "../ModalFooterContent/ModalFooterContent";
import { SUCCESS_STRINGS } from "../../../lib/utils/constants";

export const WayfinderCustomLanguageModalForm = ({
  toggleModal,
  langObj,
  langName,
  langId,
  refetch,
  refetchLanguages,
}: PropsWithChildren<{
  toggleModal: () => void;
  langObj?: Uint8Array;
  langName: string;
  langId: string;
  refetch: () => void;
  refetchLanguages: () => void;
}>) => {
  const [loading, setLoading] = useState(false);
  const [mounted, setMounted] = useState(false);

  const { updateCustomLanguage } = useCustomLanguageClient();

  let decoder = new TextDecoder();

  let decodedDataObject = decoder.decode(langObj);

  let parsedDataObject = JSON.parse(decodedDataObject);

  const formik = useCustomWayfinderLanguageFormik(
    {
      name: langName,
      ...parsedDataObject,
    },
    {
      onSubmit: async (values) => {
        try {
          setMounted(true);
          setLoading(true);

          const { name, ...languageObject } = values;

          let jsonLanguageData = JSON.stringify(languageObject);
          let encoder = new TextEncoder();
          let languageDataAsUintArray = encoder.encode(jsonLanguageData);

          await updateCustomLanguage({
            id: langId,
            name: formik.values.name || "",
            languageData: languageDataAsUintArray,
          });

          refetch();

          refetchLanguages();
          toast.success(SUCCESS_STRINGS.customLanguageUpdated);
        } catch (error: any) {
          toast.error(
            "Custom language couldn't be updated, please reload and try again!"
          );
        } finally {
          setMounted(false);
          setLoading(false);
        }
      },
    }
  );

  useEffect(() => {
    return () => {
      setMounted(false);
    };
  }, []);

  const defineJsxElement = (key: string, value: any, parentKey?: string) => {
    const inputName = parentKey ? `${parentKey}.${key}` : key;

    if (typeof value === "string") {
      const error = getIn(formik.errors, inputName);
      const touched = getIn(formik.touched, inputName);

      //Inputs should have the original English value from default langauge object as placeholders
      const defaultValue = getIn(defualtWayfinderLanguage, inputName);

      return (
        <div className="pb-3" key={`${parentKey}-${key}`}>
          <LabelWithInformationBox for={inputName} title={formatKeyName(key)} />
          <Input
            id={inputName}
            {...formik.getFieldProps(inputName)}
            isTouched={true}
            errorMessage={error}
            placeholder={defaultValue}
            invalid={!!error && touched}
          />
        </div>
      );
    } else if (typeof value === "object") {
      return (
        <FormSection
          className={parentKey ? "pl-3" : "pl-0"}
          key={key}
          title={formatKeyName(key)}
          toggleable
        >
          {Object.entries(value)
            .sort((a, b) => a[0].localeCompare(b[0]))
            .map(([childKey, childValue]) => {
              return defineJsxElement(childKey, childValue, inputName);
            })}
        </FormSection>
      );
    }
  };

  return (
    <>
      <ModalBody>
        <FormGroup className="CustomLanguageModal__name">
          <LabelWithInformationBox for="name" title="Name" />
          <Input
            id="name"
            type="text"
            name="name"
            value={formik.values.name}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            isTouched={formik.touched.name}
            errorMessage={formik.errors.name}
            invalid={!!formik.errors.name && formik.touched.name}
          />
        </FormGroup>
        <InputDescription
          title="Note!"
          description="
          Variables, ie words with {curly} brackets, must not be translated or modified - please leave as is."
        />
        {Object.entries(parsedDataObject)
          .sort((a, b) => a[0].localeCompare(b[0]))
          .map(([key, value]) => {
            return defineJsxElement(key, value);
          })}
      </ModalBody>

      <ModalFooterContent
        toggleModal={toggleModal}
        handleSave={() => formik.submitForm()}
        className="justify-content-center"
        disabled={loading || !(formik.dirty && formik.isValid)}
        loadSpinner={loading}
      />
    </>
  );
};
