import React from "react";
import cs from "classnames";

import { Button, ThemeButtonProps } from "../../shared/Button/Button";

interface Props extends ThemeButtonProps {
  parentId?: string;
  adjustable?: boolean;
  onClick?: () => void;
}

export const DeleteMarkerButton = (props: Props) => {
  const {
    outline,
    size,
    title,
    lineHeight,
    color,
    className,
    disabled,
    onClick,
  } = props;

  const classes = cs(
    {
      "adjustable-button": props.adjustable,
    },
    className
  );

  return (
    <div className={classes}>
      <Button
        size={size}
        color={color}
        disabled={disabled}
        outline={!!outline}
        lineHeight={lineHeight}
        title={title ? title : "-"}
        onClick={onClick}
      />
    </div>
  );
};
