import React, { useMemo } from "react";
import { addDays, differenceInMinutes, format, isToday, set } from "date-fns";
import { useBookingsMapContext } from "../../Context/BookingsMapContext";
import { useTimeZoneContext } from "../../../../../lib/context/TimeZone/TimeZoneContext";
import { utcToZonedTime } from "date-fns-tz";
import { getDuration } from "../../../lib/datePickerHelper";
import { BusyTimeSlots } from "../../../../../api/grpc/desk/ggevent/ggevent";

interface Props {
  isBooked: boolean;
  busySlot: BusyTimeSlots[];
}

const timeFormat = "HH:mm";
const datetimeFormat = "dd MMM, HH:mm";

export const PopUpAvailablitity = ({ isBooked, busySlot }: Props) => {
  const { timeZone } = useTimeZoneContext();

  const { dateAndTime } = useBookingsMapContext();

  const definedTime =
    differenceInMinutes(new Date(), new Date(dateAndTime)) <= 0
      ? dateAndTime
      : new Date().toISOString();

  const availabilityText = useMemo<string>(() => {
    if (!busySlot) {
      return "";
    }

    if (busySlot[0]?.isAllDay === true && isBooked) {
      return "All day";
    }

    if (isBooked && busySlot[0]?.endTime) {
      const { days, hours, minutes } = getDuration(
        new Date(definedTime),

        new Date(busySlot[0]?.endTime)
      );

      const untilTime = format(
        utcToZonedTime(new Date(busySlot[0]?.endTime), timeZone),
        isToday(new Date(busySlot[0]?.endTime)) ? timeFormat : datetimeFormat
      );

      return `for ${days ? `${days}d ` : ""}${hours ? `${hours}h ` : ""}${
        minutes ? `${minutes}min` : ""
      } until ${untilTime}`;
    }

    if (!isBooked && busySlot[0]?.startTime) {
      const { days, hours, minutes } = getDuration(
        new Date(definedTime),
        new Date(busySlot[0]?.startTime)
      );

      const untilTime = format(
        utcToZonedTime(new Date(busySlot[0]?.startTime), timeZone),
        isToday(new Date(busySlot[0]?.startTime)) ? timeFormat : datetimeFormat
      );

      return `for ${days ? `${days}d ` : ""}${hours ? `${hours} h ` : ""}${
        minutes ? `${minutes} min ` : ""
      }until ${untilTime}`;
    }

    const { hours, minutes } = getDuration(
      utcToZonedTime(new Date(definedTime), timeZone),
      set(addDays(utcToZonedTime(new Date(definedTime), timeZone), 1), {
        hours: 0,
        minutes: 0,
        seconds: 0,
      })
    );

    const untilNextDay = format(
      addDays(utcToZonedTime(new Date(definedTime), timeZone), 1),
      "dd MMM"
    );

    return `for ${hours ? `${hours} h ` : ""}${
      minutes ? `${minutes} min ` : ""
    }until ${untilNextDay}, 00:00`;
  }, [busySlot, timeZone]);

  return (
    <div className="BookingsMapPopUp__availability mt-2 mb-1">
      <div className="text-black">{availabilityText}</div>
    </div>
  );
};
