import React from "react";
import { differenceInDays, format } from "date-fns";
import { usePaginationHelper } from "../../../lib/hooks/usePaginationHelper";
import { useBookingsMapContext } from "../../Bookings/BookingsMap/Context/BookingsMapContext";
import { useTimeZoneContext } from "../../../lib/context/TimeZone/TimeZoneContext";
import { utcToZonedTime } from "date-fns-tz";
import { PaginationContext } from "../../../lib/context/Pagination/PaginationContext";
import { Pagination } from "../../shared/Pagination/Pagination";
import { StructureTable } from "../../shared/StructureTable/StructureTable";
import { FloorChildrenEntity } from "./FloorChildrenList";

interface Props {
  data: FloorChildrenEntity[];
  onClose: () => void;
  typeOfMap?: "bookings" | "floor";
}

export const FloorPoiExpandedList = ({ data, onClose, typeOfMap }: Props) => {
  const { page, pageSize, pagination } = usePaginationHelper(data.length);
  const { timeZone } = useTimeZoneContext();
  const { day } = useBookingsMapContext();

  const indexOfLastRoom = page * pageSize;
  const indexOfFirstRoom = indexOfLastRoom - pageSize;
  const currentRoomData = data?.slice(indexOfFirstRoom, indexOfLastRoom);

  const columns = [
    { key: "name", header: "Name", onClick: () => onClose() },
    { key: "add", header: "Add" },
  ];

  const rows = currentRoomData?.map((item: FloorChildrenEntity) => {
    return {
      name: (
        <div
          className={`w-full overflow-text ${
            item.location?.length ? "name__adjust" : ""
          }`}
        >
          {item.name}
        </div>
      ),
      add: item.add,
    };
  });

  return (
    <PaginationContext.Provider value={pagination}>
      <StructureTable rows={rows} columns={columns} />
      <Pagination className="pb-3" />
    </PaginationContext.Provider>
  );
};
