import React from "react";
import { BusyTimeSlots } from "../../../api/grpc/desk/ggevent/ggevent";
import { IconDefinition } from "../../shared/Icon/Icon.generated";
import { LoadingBox } from "../../shared/LoadingBox/LoadingBox";
import { FloorChildrenType } from "./FloorChildrenExpandedList";
import { FloorChildrenRow } from "./FloorChildrenRow";

export interface FloorChildrenEntity {
  name: string | JSX.Element;
  add: JSX.Element;
  location?: string;
  nextReservation?: BusyTimeSlots[];
  isBooked?: boolean;
  isAssigned?: boolean;
}

export interface EntityData {
  data: FloorChildrenEntity[] | undefined;
  type: FloorChildrenType;
  icon: IconDefinition;
  title?: string;
}

interface Props {
  rows: EntityData[];
  typeOfMap?: "bookings" | "floor";
}
export const FloorChildrenList = ({ rows, typeOfMap }: Props) => {
  return (
    <div>
      {rows.map((item: EntityData) =>
        item.data === undefined ? (
          <LoadingBox key={`${item.type}-loader`} />
        ) : item.data.length > 0 ? (
          <FloorChildrenRow
            key={`${item.type}-listing`}
            data={item.data}
            type={item.type}
            icon={item.icon}
            title={item.title}
            onClick={() => {}}
            typeOfMap={typeOfMap}
          />
        ) : (
          <></>
        )
      )}
    </div>
  );
};
