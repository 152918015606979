import React from "react";
import { getInitials } from "../../../lib/helpers/getInitials";
import { Person } from "../../../api/grpc/contacts/contacts";

interface Props {
  attendee: Person;
  showReservationInfo: boolean;
}

export const UserLeafletMarker = ({ attendee }: Props) => {
  return (
    <div className="UserLeafletMarker">
      <svg
        width="40"
        height="50"
        viewBox="0 0 40 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M40 20C40 32.4825 26.1525 45.4825 21.5025 49.4975C21.0693 49.8232 20.542 49.9994 20 49.9994C19.458 49.9994 18.9307 49.8232 18.4975 49.4975C13.8475 45.4825 0 32.4825 0 20C0 14.6957 2.10714 9.60859 5.85786 5.85786C9.60859 2.10714 14.6957 0 20 0C25.3043 0 30.3914 2.10714 34.1421 5.85786C37.8929 9.60859 40 14.6957 40 20Z"
          fill="#0D99FF"
        />
      </svg>

      <div className="UserLeafletMarker__icon">
        {!!attendee.avatar?.url.length ? (
          <img src={attendee.avatar.url} alt="User" />
        ) : (
          <span>{getInitials(attendee.displayName)}</span>
        )}
      </div>
    </div>
  );
};
