import gql from "graphql-tag";

import { DESK_FIELDS_FRAGMENT } from "../desks/desks";
import { ROOM_FIELDS_FRAGMENT } from "../rooms/rooms";
import { ZONE_FIELDS_FRAGMENT } from "../zones/zones";

import {
  WorkplaceFields,
  WorkplaceTenantFields,
  WorkplaceDefaultFields,
} from "../workplaces";
import { WAY_FINDER_FIELDS_FRAGMENT } from "../wayfinder/wayfinder";
import { Marker } from "../../grpc/workplaces/workplaces";
import { PoiIconType } from "../../../components/Floors/lib/poiMarkersHelper";

export interface FloorFields extends WorkplaceDefaultFields {
  zones?: WorkplaceFields[];
  rooms?: WorkplaceFields[];
  desks?: WorkplaceFields[];
  wayfinders?: WorkplaceFields[];
  floorPOI?: FloorPOI[];
  map: Map | null;
}

export interface MapFields {
  id: string;
  url: string;
  opacity: number;
  showGridline: boolean;
  showTooltips: boolean;
  showRoomMarkers: boolean;
}

export interface UpdateMapResponse {
  updateMap: {
    map: MapFields[];
  };
}

export interface UpdateFloorResponse {
  updateFloor: {
    floor: FloorFields[];
  };
}

export interface Map {
  id: string;
  url: string;
  opacity: number;
  showGridline: boolean;
  showTooltips: boolean;
  showRoomMarkers: boolean;
}

export interface FloorPOI {
  id: string;
  type: PoiIconType;
  marker: Marker;
}

export interface FloorRequestVariables extends WorkplaceTenantFields {
  id: string;
  name: string;
  tags: string[];
  description: string;
  map: Map | null;
  remove?: {
    tags: string[];
  };
}

export interface AddFloorVariables extends WorkplaceTenantFields {
  id: string;
  name: string;
  tags: string[];
  description: string;
  map: Map | null;
}

export interface AddFloorResponse {
  addFloor: {
    floor: FloorFields[];
  };
}

export interface AddPOIVariables {
  floorId: string;
  tenantId: string;
  type: PoiIconType;
  marker: Marker;
}

export interface AddPOIResponse {
  addPOI: {
    pOI: {
      id: string;
      type: PoiIconType;
      tenantId: string;
      marker: Marker;
      floor: {
        id: string;
      };
    }[];
  };
}

export interface DeletePOIVariables {
  id: string[];
  floorId: string;
}

export interface DeletePOIResponse {
  deletePOI: {
    numUids: number;
  };
}

export interface UpdatePOIVariables {
  id: string;
  floorId: string;
  marker: Marker;
}

export interface UpdatePOIResponse {
  updatePOI: {
    numUids: number;
  };
}

export const MAP_FIELDS_FRAGMENT = gql`
  fragment MapFields on Map {
    id
    url
    opacity
    showGridline
    showTooltips
    showRoomMarkers
  }
`;

export const FLOOR_FIELDS_FRAGMENT = gql`
  fragment FloorFields on Floor {
    id
    name
    tags
    description
    map {
      id
      url
      opacity
      showGridline
      showTooltips
      showRoomMarkers
    }
    floorPOI {
      id
      type
      marker {
        latitude
        longitude
      }
    }
    zones(order: { asc: name }) {
      ...ZoneFields
    }
    rooms(order: { asc: name }) {
      ...RoomFields
    }
    desks(order: { asc: name }) {
      ...DeskFields
    }
    wayfinders(order: { asc: name }) {
      ...WayfinderFields
    }
  }
  ${ZONE_FIELDS_FRAGMENT}
  ${ROOM_FIELDS_FRAGMENT}
  ${DESK_FIELDS_FRAGMENT}
  ${WAY_FINDER_FIELDS_FRAGMENT}
`;

export const ADD_FLOOR_TO_TENANT = gql`
  mutation addFloorToTenantMutation(
    $id: ID
    $name: String
    $description: String
    $map: MapRef
    $tags: [String]
    $tenantId: String!
  ) {
    addFloor(
      input: [
        {
          name: $name
          tags: $tags
          description: $description
          map: $map
          tenantId: $tenantId
          tenant: { id: $id }
        }
      ]
    ) {
      floor {
        ...FloorFields
      }
    }
  }
  ${FLOOR_FIELDS_FRAGMENT}
`;

export const ADD_FLOOR_TO_COMPANY = gql`
  mutation addFloorToCompanyMutation(
    $id: ID
    $name: String
    $description: String
    $map: MapRef
    $tags: [String]
    $tenantId: String!
  ) {
    addFloor(
      input: [
        {
          name: $name
          tags: $tags
          description: $description
          map: $map
          tenantId: $tenantId
          company: { id: $id }
        }
      ]
    ) {
      floor {
        ...FloorFields
      }
    }
  }
  ${FLOOR_FIELDS_FRAGMENT}
`;

export const ADD_FLOOR_TO_SITE = gql`
  mutation addFloorToSiteMutation(
    $id: ID
    $name: String
    $description: String
    $map: MapRef
    $tags: [String]
    $tenantId: String!
  ) {
    addFloor(
      input: [
        {
          name: $name
          tags: $tags
          description: $description
          map: $map
          tenantId: $tenantId
          site: { id: $id }
        }
      ]
    ) {
      floor {
        ...FloorFields
      }
    }
  }
  ${FLOOR_FIELDS_FRAGMENT}
`;

export const ADD_FLOOR_TO_BUILDING = gql`
  mutation addFloorToBuildingMutation(
    $id: ID
    $name: String
    $description: String
    $map: MapRef
    $tags: [String]
    $tenantId: String!
  ) {
    addFloor(
      input: [
        {
          name: $name
          tags: $tags
          description: $description
          map: $map
          tenantId: $tenantId
          building: { id: $id }
        }
      ]
    ) {
      floor {
        ...FloorFields
      }
    }
  }
  ${FLOOR_FIELDS_FRAGMENT}
`;

export const UPDATE_FLOOR = gql`
  mutation updateFloorMutation(
    $id: ID!
    $name: String
    $tags: [String]
    $description: String
    $map: MapRef
    $tenantId: String!
    $remove: FloorPatch
  ) {
    updateFloor(
      input: {
        filter: { id: [$id] }
        set: {
          name: $name
          tags: $tags
          description: $description
          map: $map
          tenantId: $tenantId
        }
        remove: $remove
      }
    ) {
      floor {
        ...FloorFields
      }
    }
  }
  ${FLOOR_FIELDS_FRAGMENT}
`;

export const REMOVE_FLOOR_MAP = gql`
  mutation removeMapFromFloor($id: ID!, $mapId: ID) {
    updateFloor(
      input: { filter: { id: [$id] }, remove: { map: { id: $mapId } } }
    ) {
      floor {
        id
        map {
          id
          url
          opacity
          showGridline
          showTooltips
          showRoomMarkers
        }
      }
    }
  }
`;

export const UPDATE_FLOOR_MAP_SETTINGS = gql`
  mutation updateFloorMapSettings(
    $id: ID!
    $opacity: Float
    $showGridline: Boolean
    $showTooltips: Boolean
    $showRoomMarkers: Boolean
    $url: String
  ) {
    updateMap(
      input: {
        filter: { id: [$id] }
        set: {
          opacity: $opacity
          showGridline: $showGridline
          showTooltips: $showTooltips
          showRoomMarkers: $showRoomMarkers
          url: $url
        }
      }
    ) {
      map {
        ...MapFields
      }
    }
  }
  ${MAP_FIELDS_FRAGMENT}
`;

export const ADD_POI_TO_FLOOR = gql`
  mutation addPOI(
    $floorId: ID!
    $tenantId: String!
    $type: POIType
    $marker: MarkerRef
  ) {
    addPOI(
      input: {
        floor: { id: $floorId }
        tenantId: $tenantId
        type: $type
        marker: $marker
      }
    ) {
      pOI {
        id
        type
        tenantId
        marker {
          latitude
          longitude
        }
        floor {
          id
        }
      }
    }
  }
`;

export const DELETE_POI_FROM_FLOOR = gql`
  mutation deletePOI($id: [ID!]!) {
    deletePOI(filter: { id: $id }) {
      numUids
    }
  }
`;

export const UPDATE_POI = gql`
  mutation updatePOI($id: ID!, $marker: MarkerRef!) {
    updatePOI(input: { filter: { id: [$id] }, set: { marker: $marker } }) {
      numUids
    }
  }
`;
