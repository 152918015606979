import React, { useMemo } from "react";
import { debounce } from "lodash";
import { useAuthContext } from "../../../lib/context/Auth/AuthContext";
import { useManageShapes } from "./lib/useManageShapes";
import { useEmptyRoomsAndDesks } from "../../Bookings/lib/useEmptyRoomsAndDesks";
import { useMarker } from "../lib/useMarkerData";
import { useTypesHelper } from "../lib/useTypesHelper";
import { useWorkplaceRequests } from "../../../api/graphql/useWorkplaceRequests";
import { trimText } from "../../../lib/helpers/trimText";

import { Icon } from "../../shared/Icon/Icon";
import { AddMarkerButton } from "../MarkerButtons/AddMarkerButton";
import { FloorFields } from "../../../api/graphql/floors/floors";
import { DeleteMarkerButton } from "../MarkerButtons/DeleteMarkerButton";
import { EntityData, FloorChildrenList } from "./FloorChildrenList";
import { PoiIconType, poiTypeNames } from "../lib/poiMarkersHelper";

export type FloorChildrenType = "room" | "desk" | "wayfinder";
export type TypeOfFloorChildrenMethod = "add" | "delete";

interface Props {
  data: FloorFields;
  onClick: (id: string) => void;
  onClickShape: (id: string, type: "circle" | "polygonal") => void;
  handleAddRoomButtonClick: (id?: string) => void;
}

export const FloorChildrenDefaultList = ({
  data,
  onClick,
  onClickShape,
  handleAddRoomButtonClick,
}: Props) => {
  const { user } = useAuthContext();

  const { methodTypes, markerDataTypes } = useTypesHelper();
  const { roomsOfFloor, desksOfFloor, wayfindersfFloor } = useMarker(data);
  const { handleRoomCircleDelete, handleRoomPolygonalDelete } = useManageShapes(
    user?.customerid || ""
  );
  const { addPOIToFloor, deletePOI } = useWorkplaceRequests();

  const emptyData = useEmptyRoomsAndDesks(
    roomsOfFloor,
    desksOfFloor,
    wayfindersfFloor
  );

  const mapDoesntExists = data?.map === null;

  const debouncedHandleAddPOI = useMemo(
    () =>
      debounce(async (type: PoiIconType) => {
        try {
          await addPOIToFloor({
            floorId: data.id,
            tenantId: user?.customerid || "",
            marker: markerDataTypes["add"],
            type,
          });
        } catch (error: any) {
          console.error("Error adding POI:", error);
        }
      }, 300),
    [data?.id, user?.customerid, addPOIToFloor, markerDataTypes]
  );

  const debouncedHandleDeletePOI = useMemo(
    () =>
      debounce(async (id: string) => {
        try {
          await deletePOI({
            id: [id],
            floorId: data.id,
          });
        } catch (error: any) {
          console.error("Error deleting POI:", error);
        }
      }, 300),
    [data?.id, deletePOI]
  );

  const handleOpenMarkerPopup = (item: any) => async () => {
    const hasPolygonal = item?.polygonal?.length > 0;
    const hasCircle = item?.circleShape?.latitude !== 0;

    const markerIsOnMap =
      item?.marker?.latitude !== 0 || hasPolygonal || hasCircle;

    if (!markerIsOnMap) {
      return;
    }

    if (item.__typename === "Room") {
      if (hasPolygonal || hasCircle) {
        return onClickShape(item.id, hasPolygonal ? "polygonal" : "circle");
      }
    }

    return onClick(item.id);
  };

  const handleFloorChildrenUpdate =
    (
      id: string,
      type: FloorChildrenType,
      typeOfMethod: TypeOfFloorChildrenMethod
    ) =>
    async () => {
      let methodName = methodTypes[type];
      let methodType = markerDataTypes[typeOfMethod];

      return await methodName({
        id: id,
        marker: methodType,
        tenantId: user?.customerid || "",
      });
    };

  const handleAddPOI = (type: PoiIconType) => {
    debouncedHandleAddPOI(type);
  };

  const handleDeletePOI = (id: string) => {
    debouncedHandleDeletePOI(id);
  };

  const rowsData: EntityData[] = useMemo(() => {
    const sortedRooms = roomsOfFloor
      ? [...roomsOfFloor].sort((a, b) => a.name.localeCompare(b.name))
      : [];
    const sortedDesks = desksOfFloor
      ? [...desksOfFloor].sort((a, b) => a.name.localeCompare(b.name))
      : [];
    const sortedWayfinders = wayfindersfFloor
      ? [...wayfindersfFloor].sort((a, b) => a.name.localeCompare(b.name))
      : [];

    const poiCounts: Record<string, { count: number; ids: string[] }> =
      data?.floorPOI?.reduce((acc, poi) => {
        if (!acc[poi.type]) {
          acc[poi.type] = { count: 0, ids: [] };
        }
        acc[poi.type].count += 1;
        acc[poi.type].ids.push(poi.id);
        return acc;
      }, {} as Record<string, { count: number; ids: string[] }>) || {};

    const poiList = Object.entries(poiTypeNames).map(([type, name]) => {
      const poiData = poiCounts[type as PoiIconType] || { count: 0, ids: [] };
      const count = poiData.count;
      const firstId = poiData.ids[0];

      return {
        name: name,
        location: "",
        add: mapDoesntExists ? (
          <></>
        ) : (
          <div className="flex-a-center gap-1">
            {count > 0 && (
              <>
                <DeleteMarkerButton
                  color="third"
                  className="DeleteMarkerButton"
                  onClick={() => firstId && handleDeletePOI(firstId)}
                />
                <span>{count}</span>
              </>
            )}
            <AddMarkerButton
              className="AddMarkerButton"
              onClick={() => handleAddPOI(type as PoiIconType)}
            />
          </div>
        ),
      };
    });

    return [
      {
        data:
          data?.rooms &&
          sortedRooms.map((item: any) => {
            return {
              name: (
                <span
                  onClick={handleOpenMarkerPopup(item)}
                  className={
                    (!!item?.marker?.latitude ||
                      !!item?.circleShape?.latitude) &&
                    !mapDoesntExists
                      ? "primary--text"
                      : ""
                  }
                >
                  {trimText(item.name, 20)}
                </span>
              ),
              location: item.zoneName ? trimText(item.zoneName, 20) : "",
              add: mapDoesntExists ? (
                <></>
              ) : item?.marker?.latitude === 0 &&
                (item?.circleShape === null ||
                  item?.circleShape?.latitude === 0) &&
                !item?.polygonal?.length ? (
                <AddMarkerButton
                  parentId={item.id}
                  className="AddMarkerButton"
                  onClick={handleFloorChildrenUpdate(item.id, "room", "add")}
                  allowShapeDraw
                  handleAddRoomButtonClick={handleAddRoomButtonClick}
                />
              ) : (
                <DeleteMarkerButton
                  className="DeleteMarkerButton"
                  color="third"
                  onClick={() => {
                    if (item?.circleShape?.latitude !== 0) {
                      handleRoomCircleDelete(item.id)();
                    }
                    if (item?.polygonal?.length) {
                      handleRoomPolygonalDelete(item.id, item.polygonal)();
                    }
                    if (item?.marker?.latitude !== 0) {
                      handleFloorChildrenUpdate(item.id, "room", "delete")();
                    }
                  }}
                />
              ),
            };
          }),
        type: "Room",
        icon: "rooms-icon",
      },
      {
        data:
          data?.desks &&
          sortedDesks.map((item: any) => {
            return {
              name: (
                <span
                  onClick={handleOpenMarkerPopup(item)}
                  className={!!item?.marker?.latitude ? "primary--text" : ""}
                >
                  {trimText(item.name, 20)}
                  {!!item.account ? <Icon icon="single-user-icon" /> : ""}
                </span>
              ),
              location: item.zoneName ? trimText(item.zoneName, 25) : "",
              add: mapDoesntExists ? (
                <></>
              ) : item?.marker?.latitude === 0 ? (
                <AddMarkerButton
                  className="AddMarkerButton"
                  onClick={handleFloorChildrenUpdate(item.id, "desk", "add")}
                />
              ) : (
                <DeleteMarkerButton
                  className="DeleteMarkerButton"
                  color="third"
                  onClick={handleFloorChildrenUpdate(item.id, "desk", "delete")}
                />
              ),
            };
          }),
        type: "Desk",
        icon: "desk_icon_48x48",
      },
      {
        data:
          data?.wayfinders &&
          sortedWayfinders.map((item: any) => {
            return {
              name: (
                <span
                  onClick={handleOpenMarkerPopup(item)}
                  className={!!item?.marker?.latitude ? "primary--text" : ""}
                >
                  {trimText(item.name, 20)}
                  {!!item.account ? <Icon icon="single-user-icon" /> : ""}
                </span>
              ),
              location: item.zoneName ? trimText(item.zoneName, 25) : "",
              add: mapDoesntExists ? (
                <></>
              ) : item.marker === null || item?.marker?.latitude === 0 ? (
                <AddMarkerButton
                  className="AddMarkerButton"
                  onClick={handleFloorChildrenUpdate(
                    item.id,
                    "wayfinder",
                    "add"
                  )}
                />
              ) : (
                <DeleteMarkerButton
                  className="DeleteMarkerButton"
                  color="third"
                  onClick={handleFloorChildrenUpdate(
                    item.id,
                    "wayfinder",
                    "delete"
                  )}
                />
              ),
            };
          }),
        type: "Wayfinder",
        icon: "wayFinder_icon_48x48",
      },
      {
        data: poiList,
        type: "PointOfInterest",
        icon: "exit",
        title: "Points of Interest",
      },
    ];
  }, [data]);

  return (
    <>
      <div className="FloorContent__children--header flex-a-center mb-2">
        <Icon icon="add-workplace-icon" />
        <h5>Add workspaces to your map</h5>
      </div>
      <span className="label__main">
        Press the "+" button to draw shapes over your rooms, or place a marker
        in the correct spot. The workspace items will be automatically assigned.
      </span>

      {emptyData && (
        <h4 className="FloorChildrenDefaultList__empty mt-4">
          No rooms, desks or wayfinders
        </h4>
      )}

      <div className="mt-4">
        <FloorChildrenList rows={rowsData} />
      </div>
    </>
  );
};
