import React from "react";
import { useRoomTemplateContext } from "../../../lib/context/Templates/RoomTemplate/RoomTemplateContext";
import { useDeskTemplateContext } from "../../../lib/context/Templates/DeskTemplate/DeskTemplateContext";

import { HandleRequestState } from "../../shared/HandleRequestState/HandleRequestState";
import { ProfileTable } from "./ProfileTable/ProfileTable";
import { HandleLoadingState } from "../../shared/HandleLoadingState/HandleLoadingState";
import { LoadingBox } from "../../shared/LoadingBox/LoadingBox";

import "./ProfilesList.scss";

export const ProfilesList = () => {
  const { roomTemplates, loadingRoomTemplate, errorRoomTemplates } =
    useRoomTemplateContext();
  const { deskTemplates, loadingDeskTemplate, errorDeskTemplates } =
    useDeskTemplateContext();

  return (
    <div className="ProfilesList">
      <h5>
        Workspace settings templates (
        {roomTemplates?.length + deskTemplates?.length})
      </h5>
      <span className="ProfilesList__subTitle mb-3">
        Create a template for quick bulk transfer of settings for workspaces.
      </span>

      <div className="ProfilesList__content">
        <HandleLoadingState
          loading={loadingDeskTemplate || loadingRoomTemplate}
          loadingPlaceholder={<LoadingBox count={3} minHeight={70} />}
        >
          <HandleRequestState
            state={
              errorDeskTemplates !== undefined ||
              errorRoomTemplates !== undefined
            }
            placeholder={<div>Error fetching room or desk templates</div>}
          >
            <ProfileTable rooms={roomTemplates} desks={deskTemplates} />
          </HandleRequestState>
        </HandleLoadingState>
      </div>
    </div>
  );
};
