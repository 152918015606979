import React, { PropsWithChildren, useMemo, useState } from "react";
import Select from "react-select";
import cs from "classnames";

import { useAuthContext } from "../../../../lib/context/Auth/AuthContext";
import { useDirectorySearch } from "../../../../lib/hooks/useDirectorySearch";

import {
  People,
  ListPeopleRequest,
} from "../../../../api/grpc/contacts/contacts";
import { LoadingBox } from "../../../shared/LoadingBox/LoadingBox";
import { Button } from "../../../shared/Button/Button";
import { OrganizerFilter } from "../../Context/Room/Overview/RoomInsightsContextProvider";
import { SearchUserCustomOption } from "../../../shared/Forms/SearchUserInput/SearchUserCustomOption";
import { DirectoryMenuList } from "../../../shared/DirectoryMenuList/DirectoryMenuList";
import { OptionType } from "../../../../lib/types/main.types";

interface Props
  extends PropsWithChildren<{
    loading: boolean;
    error: any;
    data?: People;
    organizers?: OrganizerFilter[];
    setOrganizers: React.Dispatch<
      React.SetStateAction<OrganizerFilter[] | undefined>
    >;
    handleClose: (() => void) | undefined;
    listPeople: (request: ListPeopleRequest) => Promise<any>;
    personalCalendarID: string;
  }> {}

export const HistoryPeopleSelect = ({
  loading: initialLoading,
  error: initialError,
  data: initialData,
  organizers,
  setOrganizers,
  handleClose,
  listPeople,
  personalCalendarID,
}: Props) => {
  const [selectedValues, setSelectedValues] = useState<OptionType[] | null>(
    organizers ? organizers : null
  );
  const { user } = useAuthContext();

  const { searching, searchError, searchResults, handleDirectorySearch } =
    useDirectorySearch(listPeople, personalCalendarID);

  const effectiveSearchResults = searchResults || initialData;

  const options = useMemo(() => {
    const results = effectiveSearchResults;
    const userOption = user
      ? { value: user.email, label: `${user.displayName} (You)` }
      : null;

    const dataOptions =
      results?.people.map((item) => ({
        value: item.email,
        label: item.displayName,
      })) || [];

    return userOption ? [userOption, ...dataOptions] : dataOptions;
  }, [effectiveSearchResults, user]);

  return (
    <>
      {initialLoading ? (
        <LoadingBox minHeight={50} />
      ) : initialError || searchError ? (
        <div>Error fetching people, please try again.</div>
      ) : (
        <>
          <Select
            isMulti
            onChange={(e) => {
              if (e === null) {
                return;
              }
              const values = e.map((item) => ({
                label: item.label,
                value: item.value,
              }));
              setSelectedValues(values);
            }}
            value={selectedValues}
            options={options}
            noOptionsMessage={() => "No result found"}
            isClearable
            components={{
              MenuList: (props) => (
                <DirectoryMenuList
                  {...props}
                  searching={searching}
                  handleDirectorySearch={handleDirectorySearch}
                />
              ),
              Option: SearchUserCustomOption as any,
            }}
            className={cs("SearchWorkplaceForm__createableOptions NoOption", {
              NoOption__hide: searching,
            })}
          />

          <div className="d-flex justify-content-between">
            <Button
              title="Done"
              size="small"
              color="outline-primary"
              className="Button__extra-small mt-3"
              onClick={() => {
                if (handleClose) {
                  handleClose();
                }

                if (!selectedValues && organizers?.length) {
                  setOrganizers(undefined);
                  return;
                }

                if (!selectedValues) {
                  return;
                }
                setOrganizers(selectedValues);
              }}
            />

            {!!organizers?.length && (
              <Button
                title="Clear"
                size="small"
                color="outline-secondary"
                className="Button__extra-small mt-3"
                onClick={() => {
                  setSelectedValues(null);
                  setOrganizers(undefined);
                }}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};
