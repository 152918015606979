import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { differenceInMinutes } from "date-fns";

import { TimeSelector } from "../TimeSelector/TimeSelector";
import { Icon } from "../../../shared/Icon/Icon";
import { timePickerProps } from "../../helpers/timePickerProps";
import { BOOKING_INTERVAL } from "../../../../lib/utils/constants";

interface Props {
  onChange: (date: Date | null) => void;
  endTime: Date;
  startTime: Date;
  filterPassedTime: (time: Date) => boolean;
  handleDurationChange: (hour: number, minute: number) => void;
  disabled?: boolean;
  isUpdateMode?: boolean;
  is24Hour?: boolean;
}

export const EndTimeInputs = ({
  onChange,
  endTime,
  startTime,
  filterPassedTime,
  handleDurationChange,
  disabled,
  isUpdateMode = false,
  is24Hour = false,
}: Props) => {
  const [selectedHour, setSelectedHour] = useState<number | null>(
    isUpdateMode ? 0 : null
  );
  const [selectedMinute, setSelectedMinute] = useState<number | null>(
    isUpdateMode ? 0 : BOOKING_INTERVAL
  );

  const updateForFieldFromTime = (start: Date, end: Date) => {
    const duration = differenceInMinutes(end, start);
    const hour = Math.floor(duration / 60);
    const minute = duration % 60;
    setSelectedHour(hour);
    setSelectedMinute(minute);
  };

  useEffect(() => {
    if (isUpdateMode) {
      return;
    }

    updateForFieldFromTime(startTime, endTime);
  }, [startTime, endTime]);

  return (
    <>
      <TimeSelector
        selectedHour={selectedHour}
        selectedMinute={selectedMinute}
        onDurationChange={(hour: number, minute: number) => {
          setSelectedHour(hour);
          setSelectedMinute(minute);
          handleDurationChange(hour, minute);
        }}
        is24Hour={is24Hour}
      />

      <Icon icon="time-divider" className="mx-2" />

      <div className="flex-1">
        <label>Until</label>
        <DatePicker
          {...timePickerProps}
          selected={endTime}
          onChange={(e) => {
            onChange(e);
            if (isUpdateMode) {
              setSelectedHour(0);
              setSelectedMinute(0);
            }
          }}
          filterTime={filterPassedTime}
          disabled={disabled}
          className="pb-1 SearchWorkplaceForm__timePicker"
        />
      </div>
    </>
  );
};
