import React from "react";
import { differenceInMinutes } from "date-fns";
import { useBookingsMapContext } from "../Context/BookingsMapContext";
import { useTimeZoneContext } from "../../../../lib/context/TimeZone/TimeZoneContext";
import { useFormatReservationDate } from "../../lib/datePickerHelper";
import { utcToZonedTime } from "date-fns-tz";
import { trimText } from "../../../../lib/helpers/trimText";
import { Icon, IconDefinition } from "../../../shared/Icon/Icon";
import { dateToHoursAndMinutes } from "../../lib/dateInputConvert";
import { Button } from "../../../shared/Button/Button";

import { PopUpTags } from "../BookingsMapModal/shared/PopUpTags";
import { PopUpAvailablitity } from "../BookingsMapModal/shared/PopUpAvailablitity";

import { BusyTimeSlots } from "../../../../api/grpc/workplaces/ggevent/ggevent";
import { MarkerStatuses } from "../../../Floors/lib/useMarkerData";
import { MarkerStatus } from "../../../Floors/MarkerPopup/MarkerStatus";
import { AmenitiesFilter } from "../../../../api/grpc/workplaces/workplaces";
import { ModalRow } from "../BookingsMapModal/shared/ModalRow";

import "./BookingsMapPopUp.scss";

interface Props {
  id: string;
  name: string;
  tags: string[];
  isBooked: boolean;
  isAssigned: boolean;
  isBlocked: boolean;
  type: string;
  busySlots: BusyTimeSlots[];
  handleOpenModal: () => void;
  handleReserveLaterModal: () => void;
  username?: string;
  avatar?: string;
  location?: string;
  email?: string;
  amenities?: AmenitiesFilter;
  numberOfSeats?: number;
  assigneAllowToFind?: boolean;
  assigneEmail?: string;
  attachedUserPopup?: boolean;
}

const getInitials = (name: string) => {
  return name
    .split(" ")
    .map((word) => word[0].toUpperCase())
    .join("");
};

const getIconForEquipment = (key: string): IconDefinition | null => {
  switch (key) {
    case "desk":
      return "desk-equipment";
    case "audio":
      return "audio-icon";
    case "casting":
      return "casting-icon";
    case "climate":
      return "climate-icon";
    case "chargingPort":
      return "chargin-port-icon";
    case "accessibility":
      return "accessibility-icon";
    case "videoConference":
      return "video-conference-icon";
    case "presentationTech":
      return "presentation-tech-icon";
    default:
      return null;
  }
};

export const getReserveButtonId = (id: string) => {
  return `reserve-button-${id}`;
};

export const BookingsMapPopUp = ({
  id,
  isBooked,
  isAssigned,
  isBlocked,
  location,
  type,
  name,
  tags,
  username,
  avatar,
  busySlots,
  handleOpenModal,
  handleReserveLaterModal,
  email,
  amenities,
  numberOfSeats,
  assigneAllowToFind,
  assigneEmail,
  attachedUserPopup,
}: Props) => {
  const { dateAndTime } = useBookingsMapContext();
  const { timeZoneFormatted, timeZone } = useTimeZoneContext();
  const utc = timeZoneFormatted.split(" ")[0];
  const definedUtc = utc.replace(":00", "");

  const busySlot = busySlots[0];

  const defineStatus = () => {
    if (isBlocked) {
      return MarkerStatuses.BLOCKED;
    }

    if (isAssigned) {
      return MarkerStatuses.ASSIGNED;
    }

    if (isBooked) {
      return MarkerStatuses.RESERVED;
    }

    return MarkerStatuses.AVAILABLE;
  };

  const renderEquipmentIcons = () => {
    if (!amenities) {
      return null;
    }
    return Object.entries(amenities).map(([key, value]) => {
      if (value) {
        const iconName = getIconForEquipment(key);
        if (iconName) {
          return <Icon key={key} icon={iconName} />;
        }
      }
      return null;
    });
  };

  const defineDeskUser = () => {
    if (type !== "Desk" || isBlocked || !(isBooked || isAssigned)) {
      return null;
    }
    const organizer = busySlot?.organizer;

    const isCurrentUser = email === organizer?.email || email === assigneEmail;
    const allowToFind =
      organizer?.allowToFind || isCurrentUser || assigneAllowToFind;
    const displayAvatar = getInitials(username || "");

    const defineAvatar = avatar ? (
      <img src={avatar} alt="User" />
    ) : (
      <span className="user-initials">{displayAvatar}</span>
    );

    if (!allowToFind) {
      return (
        <div className="flex-a-center justify-content-center my-2 BookingsMapPopUp__content--user">
          <Icon icon="single-user-thin" />
          <span className="pl-min text-14">-</span>
        </div>
      );
    }

    if (isBooked) {
      const displayName = isCurrentUser ? `${username} (You)` : username || "-";

      return (
        <div className="flex-a-center justify-content-center my-2 BookingsMapPopUp__content--user">
          {defineAvatar}
          <span className="pl-min text-14">{displayName}</span>
        </div>
      );
    }

    if (isAssigned) {
      return (
        <div className="flex-a-center justify-content-center my-2 BookingsMapPopUp__content--user">
          {defineAvatar}
          <span className="pl-min text-14">{username || "-"} (Assignee)</span>
        </div>
      );
    }

    return null;
  };

  const title = useFormatReservationDate(
    busySlot?.startTime?.length
      ? utcToZonedTime(new Date(busySlot.startTime), timeZone)
      : new Date()
  );

  return (
    <>
      <div className="BookingsMapPopUp">
        <div className="BookingsMapPopUp__head">
          <span className="BookingsMapPopUp__title">{trimText(name, 25)}</span>
          {location && (
            <span className="BookingsMapPopUp__location d-block text-secondary">
              {location}
            </span>
          )}
        </div>

        <div className="BookingsMapPopUp__content">
          <div>
            <MarkerStatus
              status={defineStatus()}
              nowAvailable={
                differenceInMinutes(new Date(), new Date(dateAndTime)) >= 0
              }
            />

            {!isAssigned && !isBlocked && (
              <>
                <PopUpAvailablitity isBooked={isBooked} busySlot={busySlots} />

                {type === "Room" && numberOfSeats && !isBooked && (
                  <div className="flex-a-center justify-content-center mt-3 mb-1 BookingsMapPopUp__content--seats">
                    <Icon icon="single-user-thin" />
                    <span className="pl-min">{numberOfSeats}</span>
                  </div>
                )}
              </>
            )}
          </div>

          {defineDeskUser()}

          {attachedUserPopup && isBooked && (
            <div className="w-full">
              <span className="d-block mb-1">
                Scheduled on-site day for {busySlot.organizer?.displayName}
              </span>

              <ModalRow
                icon="calendar-bookings"
                title={title}
                startTime={dateToHoursAndMinutes(
                  new Date(busySlot?.startTime),
                  timeZone
                )}
                endTime={
                  busySlot?.isAllDay
                    ? "00:00"
                    : dateToHoursAndMinutes(
                        new Date(busySlot?.endTime),
                        timeZone
                      )
                }
                allDay={busySlot?.isAllDay}
                utc={definedUtc}
                className="BookingsMapPopUp__ModalRow mb-1 pb-12"
              />
            </div>
          )}

          {type === "Room" &&
            amenities &&
            Object.entries(amenities).some(
              ([key, value]) => key !== "id" && value === true
            ) && (
              <div className="flex-a-center BookingsMapPopUp__content--equipment">
                {renderEquipmentIcons()}
              </div>
            )}

          {tags?.length > 0 && <PopUpTags tags={tags} />}
        </div>

        {!isBlocked && !isAssigned && (
          <>
            <div className="BookingsMapPopUp__divider" />

            <div className="flex-a-center gap-10">
              {isBooked && email === busySlot.organizer?.email && (
                <Button
                  id={getReserveButtonId(id)}
                  color={"outline-secondary"}
                  title={"Delete"}
                  className="BookingsMapPopUp__button--reserve"
                  onClick={handleOpenModal}
                />
              )}

              <Button
                id={getReserveButtonId(id)}
                color={"business"}
                title={`Book ${isBooked ? "for later" : "now"}`}
                icon={isBooked ? undefined : "lock-icon"}
                className="BookingsMapPopUp__button--reserve"
                onClick={handleReserveLaterModal}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};
