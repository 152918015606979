import accessibleToilet from "../../../assets/icons/accessible-toilet-poi.svg";
import battery from "../../../assets/icons/battery-poi.svg";
import coatRoom from "../../../assets/icons/coat-room-poi.svg";
import coffeeMachine from "../../../assets/icons/coffee-machine-poi.svg";
import elevator from "../../../assets/icons/elevator-poi.svg";
import exitGreen from "../../../assets/icons/exit-poi.svg";
import firstAidKit from "../../../assets/icons/first-aid-kit-poi.svg";
import guests from "../../../assets/icons/guests-poi.svg";
import itSupport from "../../../assets/icons/it-supports-poi.svg";
import kitchen from "../../../assets/icons/kitchen-poi.svg";
import library from "../../../assets/icons/library-poi.svg";
import lounge from "../../../assets/icons/lounge-poi.svg";
import officeSupplies from "../../../assets/icons/office-supplies-poi.svg";
import phone from "../../../assets/icons/phone-poi.svg";
import printer from "../../../assets/icons/printer-poi.svg";
import quietZone from "../../../assets/icons/quiet-zone-poi.svg";
import reception from "../../../assets/icons/reception-poi.svg";
import restingRoom from "../../../assets/icons/resting-room-poi.svg";
import showerRoom from "../../../assets/icons/shower-room-poi.svg";
import stairs from "../../../assets/icons/stairs-poi.svg";
import toilet from "../../../assets/icons/toilet-poi.svg";
import unbookableRoom from "../../../assets/icons/unbookable-room-poi.svg";
import waterStation from "../../../assets/icons/water-station-poi.svg";

import { createLeafletIcon } from "../../../lib/helpers/leaflet";

export type PoiIconType =
  | "accessibleToilet"
  | "battery"
  | "coatRoom"
  | "coffeeMachine"
  | "elevator"
  | "exitGreen"
  | "firstAidKit"
  | "guests"
  | "itSupport"
  | "kitchen"
  | "library"
  | "lounge"
  | "officeSupplies"
  | "phone"
  | "printer"
  | "quietZone"
  | "reception"
  | "restingRoom"
  | "showerRoom"
  | "stairs"
  | "toilet"
  | "unbookableRoom"
  | "waterStation";

const poiIcons = {
  accessibleToilet,
  battery: battery,
  coatRoom,
  coffeeMachine,
  elevator,
  exitGreen,
  firstAidKit,
  guests,
  itSupport,
  kitchen,
  library,
  lounge,
  officeSupplies,
  phone,
  printer,
  quietZone,
  reception,
  restingRoom,
  showerRoom,
  stairs,
  toilet,
  unbookableRoom,
  waterStation,
} as const;

export const poiTypeNames: Record<PoiIconType, string> = {
  accessibleToilet: "Accessible Toilet",
  battery: "Battery Charging Station",
  coatRoom: "Coat Room",
  coffeeMachine: "Coffee Machine",
  elevator: "Elevator",
  exitGreen: "Emergency Exit",
  firstAidKit: "First Aid Kit",
  guests: "Guests",
  itSupport: "IT Support",
  kitchen: "Kitchen",
  library: "Library",
  lounge: "Lounge",
  officeSupplies: "Office Supplies",
  phone: "Phone",
  printer: "Printer",
  quietZone: "Quiet Zone",
  reception: "Reception",
  restingRoom: "Resting Room",
  showerRoom: "Shower Room",
  stairs: "Stairs",
  toilet: "Toilet",
  unbookableRoom: "Unbookable Room",
  waterStation: "Water Station",
};

interface GetPoiIconOptions {
  size?: [number, number];
}

export function getPoiIcon(
  poiType: PoiIconType,
  options: GetPoiIconOptions = {}
) {
  const { size = [48, 48] } = options;

  if (!(poiType in poiIcons)) {
    throw new Error(`Invalid POI type: ${poiType}`);
  }

  return createLeafletIcon({
    svgUrl: poiIcons[poiType],
    size,
    type: "poi",
  });
}
