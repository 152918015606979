import React, { useMemo, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { components } from "react-select";
import { Spinner } from "reactstrap";
import { useBookingsContext } from "../../Context/BookingsContext";

import {
  ListPeopleFilter,
  ListPeopleRequest,
  People,
} from "../../../../api/grpc/contacts/contacts";
import { Button } from "../../../shared/Button/Button";
import { SearchUserCustomOption } from "../../../shared/Forms/SearchUserInput/SearchUserCustomOption";
import { FinishedUnaryCall } from "@protobuf-ts/runtime-rpc";

interface Props {
  loading: boolean;
  data?: People;
  error: any;
  handleDoneButton?: (e: { label: string; value: string }[]) => void;
  listPeople: (
    request: ListPeopleRequest
  ) => Promise<FinishedUnaryCall<ListPeopleRequest, People>>;
  personalCalendarID: string;
}

export const CreatablePeopleList = ({
  error: initialError,
  data: initialData,
  handleDoneButton,
  listPeople,
  personalCalendarID,
}: Props) => {
  const { attendees, handleAttendees } = useBookingsContext();
  const [searching, setSearching] = useState(false);
  const [searchResults, setSearchResults] = useState<People | undefined>(
    initialData
  );
  const [searchError, setSearchError] = useState<any>(null);

  const options = useMemo(() => {
    return (
      searchResults?.people.map((item) => ({
        value: item.email,
        label: item.displayName,
      })) || []
    );
  }, [searchResults]);

  const selectedValues = useMemo(() => {
    return attendees.map((attendee) => ({
      label: attendee,
      value: attendee,
    }));
  }, [attendees]);

  const handleDirectorySearch = async (searchQuery: string) => {
    try {
      setSearching(true);
      setSearchError(null);

      const { response } = await listPeople({
        personalCalendarID: personalCalendarID,
        customerID: "",
        query: searchQuery,
        accountID: "",
        filter: ListPeopleFilter.ALL,
        limit: 0,
      });

      setSearchResults(response);
    } catch (error) {
      setSearchError(error);
    } finally {
      setSearching(false);
    }
  };

  // Custom menu list to add the loader at the top
  const MenuList = (props: any) => {
    const hasResult = props.options.some(
      (option: any) =>
        (option.value
          .toLowerCase()
          .includes(props.selectProps.inputValue.toLowerCase()) ||
          option.label
            .toLowerCase()
            .includes(props.selectProps.inputValue.toLowerCase())) &&
        !option?.__isNew__
    );

    return (
      <components.MenuList {...props}>
        {!hasResult &&
          !searching &&
          props?.selectProps?.inputValue?.length > 0 && (
            <div className="SearchingDirectory">No result found</div>
          )}
        {searching && (
          <div className="SearchingDirectory">
            <Spinner size="sm" color="primary" className="mr-2" />
            Searching directory...
          </div>
        )}
        {props.children}
        {props.selectProps.inputValue && (
          <div
            className="SearchDirectory"
            style={{
              cursor: searching ? "not-allowed" : "pointer",
              opacity: searching ? 0.5 : 1,
            }}
            onMouseDown={(e) => {
              if (searching) return;
              e.preventDefault();
              handleDirectorySearch(props.selectProps.inputValue);
            }}
          >
            <span>Search directory</span>
          </div>
        )}
      </components.MenuList>
    );
  };

  return (
    <>
      {initialError || searchError ? (
        <div>Error fetching people, please try again.</div>
      ) : (
        <>
          <CreatableSelect
            isMulti
            className="SearchWorkplaceForm__createableOptions"
            onChange={(e) => {
              if (e === null) {
                return;
              }
              const values = e.map((item) => item.value);
              handleAttendees(values);
            }}
            value={selectedValues}
            options={options}
            placeholder="Add attendees"
            classNamePrefix={selectedValues.length >= 2 ? "MultipleVal" : ""}
            formatCreateLabel={(inputValue) => `Create "${inputValue}"`}
            components={{
              MenuList,
              Option: SearchUserCustomOption,
            }}
          />
        </>
      )}

      <span className="d-block mb-3 text-14 text-secondary font-weight-light max-w-none-important">
        Choose from the directory or manually enter an external email
      </span>

      {handleDoneButton && (
        <Button
          title="Done"
          size="small"
          color="outline-primary"
          className="Button__extra-small"
          onClick={() => {
            handleDoneButton(selectedValues);
          }}
        />
      )}
    </>
  );
};
