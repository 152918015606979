import React from "react";
import { components } from "react-select";
import { Spinner } from "reactstrap";

export function DirectoryMenuList(props: any) {
  const { searching, handleDirectorySearch } = props;

  return (
    <components.MenuList {...props}>
      {searching && (
        <div className="SearchingDirectory">
          <Spinner size="sm" color="primary" className="mr-2" />
          Searching directory...
        </div>
      )}
      {props.children}
      {props.selectProps.inputValue && (
        <div
          className="SearchDirectory"
          style={{
            cursor: searching ? "not-allowed" : "pointer",
            opacity: searching ? 0.5 : 1,
          }}
          onMouseDown={(e) => {
            if (searching) return;
            e.preventDefault();
            handleDirectorySearch(props.selectProps.inputValue);
          }}
        >
          <span>Search directory</span>
        </div>
      )}
    </components.MenuList>
  );
}
